import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, ErrorMessage } from "formik";
import axios from "axios";
import { Form, Button } from "react-bootstrap";
import { immediateToast } from 'izitoast-react';
import * as Yup from "yup";

const addDataSchema = Yup.object().shape({
  email: Yup.string().email().required("Email field is required"),
  role: Yup.string().required("Role field is required"),
});
const initValue = {
  email: "",
  role: "Admin"
};
const Forgotpassword = () => {
  const showTost = (msg,theme) => {
    theme = theme || "info";
    immediateToast(theme, {
      message: msg,
      timeout: 3000
    });
  }
  const handleSubmit = async (val) => {
    await axios
      .post(`${process.env.REACT_APP_APIURL}api/users/forgotPassword`, val)
      .then((res) => {
        if (res.data.success == false) {
          showTost(res.data.message,"error");
        }
        else {
          showTost(res.data.message,"success");
        }
      })
      .catch((err) => {
        showTost(err.message);
      });
  };

  return <>
    <Formik
      onSubmit={handleSubmit}
      initialValues={initValue}
      validationSchema={addDataSchema}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        setFieldValue,
      }) => {
        return (
          <div id="wrapper">
            <div className="height-100v d-flex align-items-center justify-content-center">
              <div className="card card-authentication1 mb-0">
                <div className="card-body">
                  <div className="card-content p-2">
                    <div className="card-title text-uppercase pb-2">Forgot Password</div>
                    <p className="pb-2">Please enter your email address. You will receive a link to create a new password via email.</p>
                    <Form
                      onSubmit={handleSubmit}
                      onChange={handleChange}>
                      <div className="form-group">
                      <label className>Role</label>
                      <div className="d-flex position-relative has-icon-right">
                          <input className="mr-2" type="radio" name="role" value="Admin" defaultChecked={values.role=="Admin"?true:false}/> Admin
                          <input className="ml-4 mr-2" type="radio" name="role" value="Doctor" defaultChecked={values.role=="Doctor"?true:false} /> Doctor
                        </div>
                        <ErrorMessage name="role" component="span" className="error" />
                      </div>
                      <div className="form-group">
                        <label className>Email Address</label>
                        <div className="position-relative has-icon-right">
                          <Field type="email" name="email" className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`} />
                          <div className="form-control-position">
                            <i className="icon-envelope-open" />
                          </div>
                        </div>
                        <ErrorMessage name="email" component="span" className="error" />
                      </div>
                      <button type="submit" className="btn btn-warning btn-block mt-3 sc11">Forgot Password</button>
                    </Form>
                  </div>
                </div>
                <div className="card-footer text-center py-3">
                  <p className="text-dark mb-0">Return to the <Link to={"/index"} style={{ color: '#ed113f' }}> Sign In</Link></p>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  </>;
}
export default Forgotpassword;