import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Post, Get } from './../common/ajax';
import { immediateToast } from 'izitoast-react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { Link, useNavigate } from 'react-router-dom';
import Appointment from './Appointment/Appointment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { AppointmentStatus } from './enum';
import { Chart } from "react-google-charts";

const validationSchema = Yup.object().shape({
    reason: Yup.string().required('Reject reason is required'),
});
const DoctorDashboard = () => {
    let navigate = useNavigate();
    const [data, setData] = useState([]);
    const [appontmentData, setAppontmentData] = useState([]);
    const [rejectDoc, setRejectDoc] = useState(false);
    const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
    const [dateRange, setDateRange] = useState([new Date(new Date().getFullYear(), 0, 1), new Date()]);
    const [startDate, endDate] = dateRange;
    const [filterStatus, setFilterStatus] = useState('');
    const [initValue, setinitValue] = useState({
        reason: "",
    });
    const clear = () => {
        setinitValue({
            reason: "",
        });
    }
    const showTost = (msg, theme) => {
        theme = theme || "info";
        immediateToast(theme, {
            message: msg,
            timeout: 3000,
            position: "topRight"
        });
    }
    const doctorId = JSON.parse(window.localStorage.getItem("AdminUser"))
    useEffect(() => {
        getAppointmentdashboard();
        if (doctorId) {
            getPost();
        }
    }, [filterStatus, startDate, endDate]);
    const getAppointmentdashboard = async () => {
        Post(`masters/getDashboardDoctorById/${doctorId}`, { "startDate": startDate, "endDate": endDate })
            .then((res) => {
                if (res.data) {
                    setAppontmentData(res.data);
                }
            }).catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER");
            })
    }
    const getPost = async () => {
        Post(`appointment/getAppointmentByDoctorId/${doctorId}`, { "filterStatus": filterStatus, "startDate": startDate, "endDate": endDate })
            .then((res) => {
                if (res.success) {
                    setData(res.data);
                } else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error in getting appointments list", "warning");
            });
    }
    const handleApproveReject = async (id, action, reason = '') => {
        try {
            const res = await Post('appointment/doctorAcceptRejectAppointment', {
                id,
                status: action,
                reason: reason,
                canceledBy: 'Doctor'
            });
            if (res.success) {
                showTost(`Request ${action}ed successfully`, "success");
                getPost();
                clear();
            } else {
                showTost(res.message, "warning");
            }
        } catch (err) {
            showTost("Error updating request status", "warning");
        }
    };
    const handleThisMonth = () => {
        const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        const endOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
        startOfMonth.setHours(0, 0, 0, 0);
        endOfMonth.setHours(23, 59, 59, 999);

        setDateRange([startOfMonth, endOfMonth]);
    };

    const handleLastMonth = () => {
        const startOfLastMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
        const endOfLastMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
        startOfLastMonth.setHours(0, 0, 0, 0);
        endOfLastMonth.setHours(23, 59, 59, 999);

        setDateRange([startOfLastMonth, endOfLastMonth]);
    };
    const handleDownloadRequest = async (id, created) => {
        try {
            const res = await Post('downloadreq/addDownloadRequest', {
                "appointmentId": id,
                "requestedBy": created
            });
            if (res._id) {
                showTost("Download Request added for this Appointment", "success");
            }
        } catch (err) {
            showTost("Error updating request status", "warning");
        }
    };
    const handleCompletedAppointment = async (id) => {
        Post(`appointment/ComplateAppointmentbyDoctor`, { "id": id })
            .then((res) => {
                if (res.success) {
                    showTost("Appointment Completed successfully", "success");
                    getPost();
                } else {
                    showTost(res.msg, "warning")
                }
            })
    }
    return (
        <div className="container-fluid">
            <div className="row py-2 align-items-center">
                <div className="col-sm-5">
                    <h4 className="page-title">Appointment</h4>
                </div>
                <div className="col-sm-7">
                    <div className="float-sm-right">
                        <span className="btn btn-sm border border-info mr-2" onClick={handleThisMonth}>
                            This Month
                        </span>
                        <span className="btn btn-sm border border-info mr-2" onClick={handleLastMonth}>
                            Last Month
                        </span>
                        <DatePicker
                            selected={startDate}
                            onChange={(update) => {
                                if (update && update[0] && update[1]) {
                                    const updatedStartDate = new Date(update[0]);
                                    const updatedEndDate = new Date(update[1]);
                                    updatedStartDate.setHours(0, 0, 0, 0);
                                    updatedEndDate.setHours(23, 59, 59, 999);
                                    setDateRange([updatedStartDate, updatedEndDate]);
                                } else if (update[0] == null && update[0] == null) {
                                    setDateRange([new Date(new Date().getFullYear(), 0, 1), new Date()]);
                                }
                                else {
                                    setDateRange(update);
                                }
                            }}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            isClearable={true}
                            className="form-control datepickerStyle"
                            placeholderText="Select Start to End Date Range"
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    {appontmentData?.appointmentCountArray?.map((x, i) => (
                        <div className="col-12 col-lg-4 col-xl-2">
                            <div className="card gradient-scooter">
                                <div className="card-body">
                                    <div className="media align-items-center" onClick={() => navigate(`/appointment`, { state: x.status })}>
                                        <div className="w-icon"><i className="fa-solid fa-calendar-check text-white" /></div>
                                        <div className="media-body ml-3 border-left-xs border-white-2">
                                            <h4 className="mb-0 ml-3 text-white">{x.count}</h4>
                                            <p className="mb-0 ml-3 extra-small-font text-white">{x.status}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="col-sm-12">
                    <div className="card">
                        <Chart
                            width={"100%"}
                            height={"400px"}
                            chartType={"AreaChart"}
                            loader={<div>{"Loading Chart"}</div>}
                            data={appontmentData?.chartDataArray}
                            options={{
                                title: "",
                                hAxis: {
                                    title: "",
                                    textStyle: {
                                        fontSize: 10,
                                    },
                                },
                                vAxis: {
                                    title: "",
                                    textStyle: {
                                        fontSize: 10,
                                    },
                                    minValue: 0
                                },
                                colors: [
                                    "#655af3",
                                    "#fd2e64"
                                ],
                            }}
                        />
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="btn-group float-sm-right">
                        <select className='form-control' onChange={(e) => { setFilterStatus(e.target.value); }} value={filterStatus}>
                            <option value="">All</option>
                            {AppointmentStatus.map((x, i) => (
                                <option key={i} value={x}>
                                    {x}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-lg-12 col-xl-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table id="default-datatable" className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Appointment Date-Time</th>
                                            <th>Patient</th>
                                            <th>Conversation Type</th>
                                            <th>Payment</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((m) => (
                                            <tr key={m._id}>
                                                <td>
                                                    {m.status == 'Rejected' || m.status == 'Cancelled' ? <span className='text-danger'>{m.status}</span> :
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="success" size="sm" drop="end" id="dropdown-basic">
                                                                <i className='fa fa-cog'></i>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {m.status == "Completed" ?
                                                                    <Dropdown.Item href='#' onClick={() => handleDownloadRequest(m._id, 'Doctor')}>
                                                                        Download Request By Doctor
                                                                    </Dropdown.Item>
                                                                    : ""}
                                                                {m.status == "Accepted" ? <>
                                                                    <Dropdown.Item href='#' onClick={() => handleCompletedAppointment(m._id)}>
                                                                        Completed
                                                                    </Dropdown.Item>
                                                                    {m.conversationType == "Chat" ?
                                                                        <Dropdown.Item href={`/chat/${m._id}`}>
                                                                            Chat
                                                                        </Dropdown.Item> : ""}</> : ""}
                                                                {m.status == "New" ? <>
                                                                    <Dropdown.Item href='#' onClick={() => handleApproveReject(m._id, 'Accepted')}>
                                                                        Accept
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href='#' onClick={() => {
                                                                        if (m.status !== 'Rejected') {
                                                                            setRejectDoc(true);
                                                                            setSelectedAppointmentId(m._id);
                                                                        } else {
                                                                            showTost("This appointment has already been rejected.", "warning");
                                                                        }
                                                                    }}
                                                                        className={` ${m.status === 'Rejected' ? 'text-secondary' : 'text-danger'}`}
                                                                        disabled={m.status === 'Rejected'}>
                                                                        Reject
                                                                    </Dropdown.Item>
                                                                </> : ""}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    }
                                                </td>
                                                <td>{moment(m.appointmentDate).format('DD-MM-YYYY')} {moment(m.appointmentTime, 'HH:mm').format('h:mm A')}</td>
                                                <td>{`${m.patientId?.firstName} ${m.patientId?.middleName} ${m.patientId?.lastName}`}</td>
                                                <td>{m.conversationType}</td>
                                                <td>{m.paymentAmount}</td>
                                                <td>{m.status}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={rejectDoc} onHide={() => setRejectDoc(false)}>
                <Modal.Header>
                    <label style={{ fontSize: "16px", textAlign: "center" }} className='text-align-center'>Status</label>
                    <button type="button" className="close" onClick={() => setRejectDoc(false)}>&times;</button>
                </Modal.Header>
                <Modal.Body className='px-4'>
                    <Formik
                        initialValues={initValue}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            handleApproveReject(selectedAppointmentId, 'Rejected', values.reason)
                            setRejectDoc(false);
                        }}>
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <div className='mt-2'>
                                    <label>Reject Reason</label>
                                    <Field
                                        as="textarea"
                                        name="reason"
                                        placeholder='Enter Reject Reason'
                                        className={`form-control ${touched.reason && errors.reason ? 'is-invalid' : ''}`}
                                    />
                                    <ErrorMessage name="reason" component="div" className="text-danger" />
                                </div>
                                <div className='mt-2'>
                                    <Button variant="success" type="submit" className='mr-2'>
                                        <i className="fa fa-check" /> Submit
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </div>
    );
}
export default DoctorDashboard;
