import React, { useEffect, useState } from 'react';
import AgoraRTC, { createClient } from 'agora-rtc-sdk-ng';
import { VideoPlayer } from './VideoPlayer';
import { Post } from '../../common/ajax';
import { useParams } from 'react-router-dom';

let CHANNEL = 'test';
let APP_ID = "";
let TOKEN = ";"
AgoraRTC.setLogLevel(4);

let agoraCommandQueue = Promise.resolve();

const createAgoraClient = ({
  onVideoTrack,
  onUserDisconnected,
}) => {
  const client = createClient({
    mode: 'rtc',
    codec: 'vp8',
  });


  let tracks;

  const waitForConnectionState = (connectionState) => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (client.connectionState === connectionState) {
          clearInterval(interval);
          resolve();
        }
      }, 200);
    });
  };

  const connect = async () => {
    await waitForConnectionState('DISCONNECTED');

    const uid = await client.join(
      APP_ID,
      CHANNEL,
      TOKEN,
      null
    );

    client.on('user-published', (user, mediaType) => {
      client.subscribe(user, mediaType).then(() => {
        if (mediaType === 'video') {
          onVideoTrack(user);
        }
      });
    });

    client.on('user-left', (user) => {
      onUserDisconnected(user);
    });

    tracks =
      await AgoraRTC.createMicrophoneAndCameraTracks();

    await client.publish(tracks);

    return {
      tracks,
      uid,
    };
  };

  const disconnect = async () => {
    await waitForConnectionState('CONNECTED');
    client.removeAllListeners();
    for (let track of tracks) {
      track.stop();
      track.close();
    }
    await client.unpublish(tracks);
    await client.leave();
  };

  return {
    disconnect,
    connect,
  };
};


export const VideoRoom = () => {
  const [users, setUsers] = useState([]);
  const [uid, setUid] = useState(null);
  const [appId, setAppId] = useState("");

  const { id } = useParams();

  useEffect(() => { getMeetingDetail() }, [id])
  useEffect(() => {
    APP_ID=appId;
    const onVideoTrack = (user) => {
      setUsers((previousUsers) => [...previousUsers, user]);
    };

    const onUserDisconnected = (user) => {
      setUsers((previousUsers) =>
        previousUsers.filter((u) => u.uid !== user.uid)
      );
    };

    const { connect, disconnect } = createAgoraClient({
      onVideoTrack,
      onUserDisconnected,
    });

    const setup = async () => {
      const { tracks, uid } = await connect();
      setUid(uid);
      setUsers((previousUsers) => [
        ...previousUsers,
        {
          uid,
          audioTrack: tracks[0],
          videoTrack: tracks[1],
        },
      ]);
    };

    const cleanup = async () => {
      await disconnect();
      setUid(null);
      setUsers([]);
    };

      // setup();
      agoraCommandQueue = agoraCommandQueue.then(setup);

      return () => {
        // cleanup();
        agoraCommandQueue = agoraCommandQueue.then(cleanup);
      };
  }, [appId]);
  const getMeetingDetail = async () => {
    await Post(`appointment/AudioVideoMeeting`, { channelName: CHANNEL, apptId: id })
      .then((res) => {
        if (res.success) {
          var data = res.data;
          //console.log(data, "data");
          //createMeetings(data.token, data.APP_ID, channelName);
          var docDetail = data.detail.doctorId;
          var patDetail = data.detail.patientId;
          // if (data.detail.conversationType === "Video") {
          //     setisVideo(true);
          // }
          //setDoctor("Dr. " + docDetail.firstName + " " + docDetail.lastName);
          //setPatient(patDetail.firstName + " " + patDetail.lastName);
          //setAppId(data.APP_ID);
          //setChannel(CHANNEL);
          //settoken(data.token);
          setAppId( data.APP_ID);
          TOKEN = data.token;

        } else {
          alert(res.message);
        }
      })
      .catch((err) => {
        alert("Error in generating meetinglink." + err,);
      });

  }
  return (
    <>
      {uid}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 200px)',
          }}
        >
          {users.map((user) => (
            <VideoPlayer key={user.uid} user={user} />
          ))}
        </div>
      </div>
    </>
  );
};
