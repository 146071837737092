import React, { useEffect, StrictMode, useState } from 'react';
import AgoraRTC, { useAgoraRTCProvider, AgoraRTCProvider } from "agora-rtc-react";
import { Post } from '../../common/ajax';
import Agora from './Agora';

const Meeting2 = () => {
    AgoraRTC.setLogLevel(1);

    const client = AgoraRTC.createClient({  mode: "rtc",
        codec: "vp8",
        advancedAudioOptions: {
          aec: true, // Enable Acoustic Echo Cancellation
          nc:true,
          agc:true
        }});
    const [calling, setCalling] = useState(false);
    useEffect(() => {
        // Listen for the `user-left` event when the component mounts
        const handleUserLeft = (user) => {
            //console.log('User has disconnected:', user.uid);
            //setCalling(false);    
            // Update the state to reflect that this user has left
            // e.g., remove user from participant list, hide video view, etc.
        };

        // Subscribe to the `user-left` event
        client.on('user-left', handleUserLeft);

        // Clean up event listener when the component unmounts
        return () => {
            client.off('user-left', handleUserLeft);
        };
    }, [client]);
   
    return (
        <>
            <AgoraRTCProvider client={client}>
                <Agora calling={calling} setCalling={setCalling}/>
            </AgoraRTCProvider>
        </>
    )
}
export default Meeting2;