import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { useToast, immediateToast } from "izitoast-react";
import "izitoast-react/dist/iziToast.css";
import * as Yup from "yup";

const addDataSchema = Yup.object().shape({
  EmailAddress: Yup.string().required(),
  password: Yup.string().required(),
  role: Yup.string().required(),
});

const initValue = {
  EmailAddress: "",
  password: "",
  role: "Admin"
};
const Login = () => {

  const handleSubmit = async (val, { setSubmitting }) => {
    setSubmitting(true);
    await axios
      .post(`${process.env.REACT_APP_APIURL}api/users/login`, val)
      .then((res) => {
        if (res.data.success == false) {
          showTost(res.data.message);
          return false;
        }
        else if (res.data.token !== null) {
          window.localStorage.setItem("AdminUser", JSON.stringify(res.data._id));
          window.localStorage.setItem("AdminToken", JSON.stringify(res.data.token));
          window.localStorage.setItem("Email", JSON.stringify(res.data.email));
          window.localStorage.setItem("AdminUsername", JSON.stringify(res.data.name));
          window.localStorage.setItem("AdminRole", JSON.stringify(res.data.role));
          window.localStorage.setItem("PasswordModalShown", JSON.stringify(res.data.is_defaultpassword));
          let d = new Date();
          var nD = d.setMinutes(d.getMinutes() + 60);
          window.localStorage.setItem("ExpiryTime", nD);
          window.location.replace("/dashboard")
        }
      })
      .catch((err) => {
        showTost(err.response.data.message);
      });
    setSubmitting(false);
  };

  const resend = async (val) => {
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val.EmailAddress)) {
      showTost("Please Enter Email Address");
      return false
    }
    await axios
      .post(`${process.env.REACT_APP_APIURL}api/Authenticate/reissueEmailConfirmationLink`, { "Email": val.EmailAddress })
      .then((res) => {
        showTost("Resend Email Confirmation Link, please check mailbox");
      })
      .catch((err) => {
        showTost("Something went wrong please try after sometime");
      });
  };

  const showTost = (msg) => {
    immediateToast("error", {
      message: msg,
      timeout: 3000,
      position:"topRight"
    });
  }

  return <>
    <Formik
      onSubmit={handleSubmit}
      initialValues={initValue}
      validationSchema={addDataSchema}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        touched,
        errors,
        setFieldValue,
      }) => {
        return (
          <div id="wrapper">
            <div className="height-100v d-flex align-items-center justify-content-center">
              <div className="card card-authentication1 mb-0">
                <div className="card-body">
                  <div className="card-content p-2">
                    <div className="card-title text-uppercase text-center py-3">Sign In</div>
                    <Form
                      onSubmit={handleSubmit}
                      onChange={handleChange}>
                      <div className="form-group">
                      <label className>Role</label>
                        <div className="d-flex position-relative has-icon-right">
                          <input className="mr-2" type="radio" name="role" value="Admin" defaultChecked={values.role=="Admin"?true:false}/> Admin
                          <input className="ml-4 mr-2" type="radio" name="role" value="Doctor" defaultChecked={values.role=="Doctor"?true:false} /> Doctor
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputUsername" className="sr-only">Username</label>
                        <div className="position-relative has-icon-right">
                          <input type="text" name="EmailAddress" className={!!touched.EmailAddress && !!errors.EmailAddress ? "form-control input-shadow is-invalid" : "form-control input-shadow"} placeholder="Enter Email/Phone" />
                          <div className="form-control-position">
                            <i className="icon-user" />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword" className="sr-only">Password</label>
                        <div className="position-relative has-icon-right">
                          <input type="password" name="password" className={!!touched.password && !!errors.password ? "form-control input-shadow is-invalid" : "form-control input-shadow"} placeholder="Enter Password" />
                          <div className="form-control-position">
                            <i className="icon-lock" />
                          </div>
                        </div>
                      </div>
                      <div className="form-row mt-2">
                        <div className="form-group col-12 text-right">
                          <Link to={"/forgot-password"} style={{ color: '#ed113f' }}>Forgot Password</Link>
                        </div>
                      </div>
                      <button type="submit" className="btn btn-primary btn-block mt-1 sc10">Sign In</button>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  </>;
}
export default Login;