import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";


const DialogBox = (props) => {
    return (
        <Modal show={props.show}>

            <Modal.Body>
                <label style={{fontSize:"16px"}}>{props.message}</label>
            </Modal.Body>
            <Modal.Footer style={{textAlign:"center",display:"block"}}>
                <Button variant="primary" onClick={props.okClick}>
                    Ok
                </Button>
                <Button variant="danger" onClick={props.close}>
                    Close
                </Button>
            </Modal.Footer>

        </Modal>
    );
};

export default DialogBox;