import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Post } from '../../common/ajax';
import { immediateToast } from 'izitoast-react';
import { Modal, Button } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import * as Yup from "yup";
import moment from "moment";

const validationSchema = Yup.object().shape({
    rejectReason: Yup.string().required('Reject reason is required'),
});
const DownloadRequest = () => {
    const showTost = (msg, theme) => {
        theme = theme || "info";
        immediateToast(theme, {
            message: msg,
            timeout: 3000,
            position: "topRight"
        });
    }
    const [initValue, setInitValue] = useState({ rejectReason: "" });
    const [popupStatus, setPopupStatus] = useState(false);
    const [rejectDoc, setRejectDoc] = useState(false);
    const [selectedRequestId, setSelectedRequestId] = useState(null);
    const [data, setData] = useState([]);
    const [status, setStatus] = useState('All');
    useEffect(() => {
        getPost();
    }, [status]);

    const getPost = async () => {
        Post(`downloadreq/getAll`, { active: true, status })
            .then((res) => {
                if (res.success) {
                    setData(res.data);
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error in getting Download Request list" + err.message , "warning");
            });
    }
    const handleDropdownAction = (id) => {
        setSelectedRequestId(id);
        setPopupStatus(true);
    }

    const handleApproveReject = async (action, rejectReason = '') => {
        const body = {
            id: selectedRequestId,
            status: action === 'Approve' ? 'Approved' : 'Reject',
            rejectReason: action === 'Reject' ? rejectReason : ''
        };
        try {
            const res = await Post('downloadreq/updateRequestStatus', body);
            if (res.success) {
                // showTost(`Request ${action}ed successfully`, "success");
                showTost(res.message, "success");
                getPost(true);
                setPopupStatus(false);
                setRejectDoc(false);
            } else {
                showTost(res.message, "warning");
            }
        } catch (err) {
            showTost("Error updating request status of Download Request" + err.message , "warning");
        }
    }
    return (
        <div className="container-fluid">
            <div className="row py-2 align-items-center">
                <div className="col-md-10 col-sm-9">
                    <h4 className="page-title">Download Request</h4>
                </div>
                <div className="col-md-2 col-sm-3">
                    <div className="btn-group float-sm-right">
                        <select className='form-control' onChange={(e) => { setStatus(e.target.value); }} value={status}>
                            <option value="All">All</option>
                            <option value="Pending">Pending</option>
                            <option value="Reject">Reject</option>
                            <option value="Approved">Approved</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table id="default-datatable" className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Status</th>
                                            <th>Requested By</th>
                                            <th>Dr. Name</th>
                                            <th>Patient Name</th>
                                            {/* <th>Document Name</th> */}
                                            <th>Conversation Type</th>
                                            <th>Appointment Date-Time</th>
                                            <th>Added Date</th>
                                            <th>Approved At</th>
                                            <th>Reject Reason</th>
                                            <th>Rejected At</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((request, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {request.status === 'Pending' ?
                                                        <button type="button" onClick={() => handleDropdownAction(request._id)} className="btn btn-inverse-primary waves-effect waves-light">
                                                            {request.status}
                                                        </button>
                                                        : request.status}
                                                </td>
                                                <td>{request.requestedBy}</td>
                                                <td>{`${request.appointmentId?.doctorId?.firstName} ${request.appointmentId?.doctorId?.middleName} ${request.appointmentId?.doctorId?.lastName}`}
                                                </td>
                                                <td>{`${request.appointmentId?.patientId?.firstName} ${request.appointmentId?.patientId?.middleName} ${request.appointmentId?.patientId?.lastName}`}
                                                </td>
                                                {/* <td>{request.document}</td> */}
                                                <td>{request.appointmentId?.conversationType}</td>
                                                <td>{moment(request.appointmentId?.appointmentDate).format('DD-MM-YYYY')} {moment(request.appointmentId?.appointmentTime, 'HH:mm').format('h:mm A')}</td>
                                                <td>{new Date(request.createdAt).toLocaleDateString()}</td>
                                                <td>{request.status === 'Approved' ? new Date(request.approvedAt).toLocaleString() : '-'}</td>
                                                <td>{request.rejectReason === '-' ? '-' : request.rejectReason || ''}</td>
                                                <td>{request.status === 'Reject' ? new Date(request.rejectedAt).toLocaleString() : '-'}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={popupStatus} onHide={() => setPopupStatus(false)}>
                <Modal.Header>
                    <label style={{ fontSize: "16px", textAlign: "center" }} className='text-align-center'>Show Status</label>
                    <button type="button" className="close" onClick={() => setPopupStatus(false)}>&times;</button>
                </Modal.Header>
                {/* <Modal.Body>

                </Modal.Body> */}
                <Modal.Footer style={{ textAlign: "center", display: "block" }}>
                    <Button variant="primary" onClick={() => handleApproveReject('Approve')}>
                        Accept
                    </Button>
                    <Button
                        variant={data.find(req => req._id === selectedRequestId)?.status === 'Reject' ? "secondary" : "danger"}
                        onClick={() => setRejectDoc(true)}
                        disabled={data.find(req => req._id === selectedRequestId)?.status === 'Reject'}
                    >
                        Reject
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={rejectDoc} onHide={() => setRejectDoc(false)}>
                <Modal.Body className='px-4'>
                    <label style={{ fontSize: "16px", textAlign: "center" }} className='text-align-center'>Status</label>
                    <Formik
                        initialValues={initValue}
                        validationSchema={validationSchema}
                        onSubmit={(values) => handleApproveReject('Reject', values.rejectReason)}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <div className='mt-2'>
                                    <label>Reject Reason</label>
                                    <Field
                                        as="textarea"
                                        name="rejectReason"
                                        placeholder='Enter Reject Reason'
                                        className={`form-control ${touched.rejectReason && errors.rejectReason ? 'is-invalid' : ''}`}
                                    />
                                    <ErrorMessage name="rejectReason" component="div" className="text-danger" />
                                </div>
                                <div className='mt-2'>
                                    <Button variant="success" type="submit" className='mr-2'>
                                        <i className="fa fa-check" /> Submit
                                    </Button>
                                    <Button variant="light" onClick={() => setRejectDoc(false)}>
                                        Close
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </div>
    )
};
export default DownloadRequest;
