import React, { useEffect, useState } from 'react';
import * as Yup from "yup";
import { Formik, Field, ErrorMessage } from "formik";
import { Post, Get, FileUpload } from '../../common/ajax';
import { Form, Button } from "react-bootstrap";
import { useParams, useNavigate } from 'react-router-dom';
import { immediateToast } from 'izitoast-react';
import Swal from "sweetalert2";
import { ConversationTypeStatus } from '../enum';
const validation = Yup.object({
    payment: Yup.array().of(
        Yup.object({
            period: Yup.string().required('Period is required'),
            typeamount: Yup.array().of(
                Yup.object({
                    conversationType: Yup.string().required('Conversation type is required'),
                    amount: Yup.number()
                        .typeError('Amount must be a number')
                        //   .required('Amount is required') 
                        .min(1, 'Amount must be greater than zero'),
                })
            )
        })
    )
});
const DoctorPayment = () => {
    let params = useParams();
    const navigate = useNavigate();
    const periodList = ["Year", "Month", "Hour"];
    const typeLimit = ConversationTypeStatus.map((val) => { return { conversationType: val, amount: "" } });
    const payment = periodList.map((dayVal) => { return { period: dayVal, typeamount: typeLimit } });
    const [initValue, setInitValue] = useState({
        id: "",
        payment: payment
    })
    const clearData = () => {
        navigate('/DoctorsMaster');
    }
    useEffect(() => {
        if (params.id != 0) {
            getById();
        }
    }, []);
    const showTost = (msg, theme) => {
        theme = theme || "info";
        immediateToast(theme, {
            message: msg,
            timeout: 3000,
            position: "topRight"
        });
    }
    const getById = async () => {
        await Get(`doctors/getDoctorPaymentById/${params.id}`)
            .then((res) => {
                if (res.data) {
                    try {
                        if (res.data) {
                            if (!res.data.payment || res.data.payment.length <= 0) {
                                res.data.payment = payment;
                            }
                            setInitValue({
                                id: res.data._id,
                                payment: res.data.payment
                            });
                        }
                    }
                    catch (err) {
                        showTost(err, "warning");
                    }
                }
            }).catch((err) => {
                showTost("Error in getting Doctor Payment detail", "warning");
            });
    }
    const handleSubmit = async (val) => {
        val.doctorid = params.doctorid;
        val.id = params.id;
        await Post(`doctors/saveDoctorpayment`, val)
            .then((res) => {
                if (res.success == true) {
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            navigate('/DoctorsMaster');
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    useEffect(() => { setInitValue(initValue) }, [initValue])
    return (
        <div className="container-fluid">
            <Formik
                enableReinitialize={true}
                onSubmit={handleSubmit}
                initialValues={initValue}
                validationSchema={validation}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    setFieldValue
                }) => {
                    return (
                        <>
                            <div className="row pt-2 pb-2">
                                <div className="col-sm-12">
                                    <h4 className="page-title">{params.id != 0 ? "Add Payment" : "Edit Payment"}</h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <Form id="doctorPaymentForm" onSubmit={handleSubmit} >
                                        {values.payment && values.payment.map((dayItem, dIndex) => {
                                            return (
                                                <div className="mb-2" key={dIndex}>
                                                    <div>
                                                        <div>
                                                            <h4>{dayItem.period}</h4>
                                                        </div>
                                                        <div className='card p-4'>
                                                            <div className='row'>
                                                                {dayItem.typeamount && dayItem.typeamount.map((item, tIndex) => {
                                                                    return (
                                                                        <div className='col-lg-4 col-xl-4 col-12' key={tIndex}>
                                                                            <div className="form-group">
                                                                                <label className="col-form-label">
                                                                                    {item.conversationType}:
                                                                                </label>
                                                                                <Field
                                                                                    type="text"
                                                                                    name={`payment[${dIndex}].typeamount[${tIndex}].amount`}
                                                                                    className={`form-control ${touched.payment?.[dIndex]?.typeamount?.[tIndex]?.amount && errors.payment?.[dIndex]?.typeamount?.[tIndex]?.amount ? "is-invalid" : ""}`}
                                                                                    value={item.amount}
                                                                                    onChange={(e) => {
                                                                                        setFieldValue(`payment[${dIndex}].typeamount[${tIndex}].amount`, e.target.value);
                                                                                    }}
                                                                                />
                                                                                <ErrorMessage name={`payment[${dIndex}].typeamount[${tIndex}].amount`} component="span" className="error" />
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}

                                        <div className="col-12">
                                            <Button type="reset" className="btn btn-danger waves-effect waves-light float-right ml-2" onClick={() => clearData()}>Cancel</Button>
                                            <Button type="submit" className="btn-success btn waves-effect waves-light float-right">Update</Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </>
                    );
                }}
            </Formik >
        </div >
    )
}

export default DoctorPayment
