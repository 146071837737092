import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const $ = window.$;

  const islogin = JSON.parse(window.localStorage.getItem("AdminToken"));
  
  if (!islogin) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;