import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Post, Get } from '../../common/ajax';
import { immediateToast } from 'izitoast-react';
import { Modal, Button } from "react-bootstrap";
import * as Yup from "yup";
import './chat.css';
import { useParams } from 'react-router-dom';

const addDataSchema = Yup.object().shape({
    Message: Yup.string().required("Message field is required"),
});
const Chat = () => {
    let params = useParams();
    const [data, setData] = useState([]);
    const [name, setname] = useState("");
    const [mobile, setmobile] = useState("");
    const [image, setimage] = useState(process.env.REACT_APP_APIURL + "static/user.png");
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    let role = JSON.parse(window.localStorage.getItem("AdminRole"));
    const showTost = (msg, theme) => {
        theme = theme || "info";
        immediateToast(theme, {
            message: msg,
            timeout: 3000,
            position: "topRight"
        });
    }
    const [initValue, setValue] = useState({
        AppointmentId: params.id,
        Message: "",
        addedBy: "Doctor"
    });
    useEffect(() => {
        getPost();
        getById();
    }, []);
    const clear = () => {
        setValue({
            AppointmentId: params.id,
            Message: "",
            addedBy: "Doctor"
        });
    }
    const getById = async () => {
        Get(`appointment/getAppointmentById/${params.id}`)
            .then((res) => {
                if (role != "Doctor") {
                    setname(res.data.doctorId?.firstName + " " + res.data.doctorId?.middleName + " " + res.data.doctorId?.lastName);
                    setmobile(res.data.doctorId?.mobileNo);
                    setimage(process.env.REACT_APP_APIURL + "static/" + res.data.doctorId?.image);
                }
                else {
                    setname(res.data.patientId?.firstName + " " + res.data.patientId?.middleName + " " + res.data.patientId?.lastName);
                    setmobile(res.data.patientId?.mobileNo);
                    setimage(process.env.REACT_APP_APIURL + "static/" + res.data.patientId?.image);
                }
            })
            .catch((err) => {
                showTost("SOMETHING WENT WRONG ON SERVER", "error");
            });
    }
    const getPost = async () => {
        Get(`appointment/GetChatList/${params.id}`)
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                showTost("Something went wrong please try after sometime");
            });
    }

    const handleSubmit = async (val) => {
        Post(`appointment/saveChat`, val)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    getPost();
                    setFormKey(Math.random() * 1000000);
                }
            })
            .catch((err) => {
                showTost("Something went wrong please try after sometime");
            });
    };

    return (
        <div className="container-fluid">
            <div className="row py-2 align-items-center">
                <div className="col-sm-10">
                    <h4 className="page-title">Chat</h4>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <main className="chat-main-wrapper p-0">
                                <div className="chat-header">
                                    <div className="chat-header-contact-master">
                                        <img className="chat-header-contact-profile-pic" src={image} />
                                        <div className="chat-header-contact-name">
                                            {name}
                                            <div className="chat-header-contact-connection-display">
                                                {mobile}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="chat-box">
                                    <div className="chat-conversation-wrapper">
                                        {data.map(x => (<>
                                            {x.addedBy != role ?
                                                <div className="contact-message-wrapper">
                                                    <div className="contact-message-box-style" />
                                                    <div className="contact-message-box">
                                                        <div className="contact-message-box-p">
                                                            {x.Message}
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className="message-wrapper">
                                                    <div className="message-box">
                                                        <div className="message-box-p">{x.Message}</div>
                                                    </div>
                                                    <div className="message-box-style" />
                                                </div>
                                            }
                                        </>))}
                                    </div>
                                    <Formik
                                        enableReinitialize={true}
                                        onSubmit={handleSubmit}
                                        initialValues={initValue}
                                        validationSchema={addDataSchema}
                                        key={formkey}
                                    >
                                        {({
                                            handleSubmit,
                                            handleChange,
                                            values,
                                            touched,
                                            errors,
                                            setFieldValue,
                                        }) => {
                                            return (
                                                <Form id="textmasterform"
                                                    onSubmit={handleSubmit}
                                                    onChange={handleChange}>
                                                    <div className="chat-user-message-wrapper">
                                                        <div className="chat-user-message-form-wrapper">
                                                            <Field value={values.Message} type="text" name="Message" id="user-message-form" className={`form-control ${touched.Message && errors.Message ? "is-invalid" : ""}`} placeholder="Type your message here" />
                                                        </div>
                                                        <button className="btnborder" type="submit"><i className="fa fa-paper-plane" id="send-message" /></button>
                                                    </div>
                                                    <ErrorMessage name="name" component="span" className="chat-user-message-wrapper error" />
                                                </Form>
                                            );
                                        }}
                                    </Formik>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chat
