import React, { Component } from 'react';

class Footer extends Component {
    loadJs(filepath) {
        let publicUrl = process.env.PUBLIC_URL + '/'
        const minscript = document.createElement("script");
        minscript.async = false;
        minscript.src = publicUrl + filepath;

        document.body.appendChild(minscript);
    }

    componentDidMount() {
        this.loadJs("assets/plugins/simplebar/js/simplebar.js");
        this.loadJs("assets/js/sidebar-menu.js");
        setTimeout(() => {
            this.loadJs("assets/js/app-script.js");
        }, 3000);
    }
    render() {
        return (
            <>
                <footer className="footer">
                    <div className="container">
                        <div className="text-center">
                            © {new Date().getFullYear()} Emoiss. All Rights Reserved &nbsp;&nbsp;
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}


export default Footer;