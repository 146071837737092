import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../../images/logos/MANOGATI LOGO2.png';

const Sidebar = () => {

  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  let role = JSON.parse(window.localStorage.getItem("AdminRole"));
  let doctorid = JSON.parse(window.localStorage.getItem("AdminUser"));
  const handleLiClick = () => {
    if (window.outerWidth < 1025) {
      var dv = document.getElementById("wrapper");
      if (dv.classList.contains("toggled")) {
        dv.classList.remove("toggled");
      }
      else {
        dv.classList.add("toggled");
      }
    }
  }
  return (
    <div id="sidebar-wrapper" data-simplebar data-simplebar-auto-hide="true">
      <div className="brand-logo">
        <Link to={"/"}>
          {/* <img src="/assets/images/logo-icon.png" className="logo-icon" alt="logo icon" /> */}
          <div className='d-flex align-items-center justify-content-between px-4'>
            <img src={logo} alt="logo" style={{marginTop:"15px", width:"90%"}}/>
            <span><i className="icon-menu menu-icon sideBar toggle-menu" /></span>
          </div>
        </Link>
      </div>
      <ul className="sidebar-menu">
        <li className="sidebar-header">MAIN NAVIGATION</li>
        {role == "Doctor" ?
          <>
            <li className={splitLocation[1] === "" ? "active" : ""} onClick={handleLiClick}>
              <Link to={"/"} className="waves-effect">
                <i className="zmdi zmdi-view-dashboard" /> <span>Dashboard</span>
              </Link>
            </li>
            <li className={splitLocation[1] === "appointment" ? "active" : ""} onClick={handleLiClick}>
              <Link to={"/appointment"} className="waves-effect">
                <i className="fa fa-calendar-check" /> <span>Appointment</span>
              </Link>
            </li>
            <li className={splitLocation[1] === "support" ? "active" : ""} onClick={handleLiClick}>
              <Link to={"/SupportMaster"} className="waves-effect">
                <i className="fa fa-comment-dots" /> <span>Support</span>
              </Link>
            </li>
            <li className={splitLocation[1] === "DoctorsAdd" || splitLocation[1] === "DoctorsAdd" ? "active" : ""} onClick={handleLiClick}>
              <Link to={`/DoctorsAdd/${doctorid}`} className="waves-effect">
                <i className="fa fa-user-doctor" /> <span>Edit Profile</span>
              </Link>
            </li>
          </> :
          <>
            <li className={splitLocation[1] === "" ? "active" : ""} onClick={handleLiClick}>
              <Link to={"/"} className="waves-effect">
                <i className="zmdi zmdi-view-dashboard" /> <span>Dashboard</span>
              </Link>
            </li>
            <li className={splitLocation[1] === "appointment" ? "active" : ""} onClick={handleLiClick}>
              <Link to={"/appointment"} className="waves-effect">
                <i className="fa fa-calendar-check" /> <span>Appointment</span>
              </Link>
            </li>
            <li className={splitLocation[1] === "PatientsMaster" || splitLocation[1] === "PatientsAdd" ? "active" : ""} onClick={handleLiClick}>
              <Link to={"/PatientsMaster"} className="waves-effect">
                <i className="fa fa-person" /> <span>Patients</span>
              </Link>
            </li>
            <li className={splitLocation[1] === "DoctorsMaster" || splitLocation[1] === "DoctorsAdd" ? "active" : ""} onClick={handleLiClick}>
              <Link to={"/DoctorsMaster"} className="waves-effect">
                <i className="fa fa-user-doctor" /> <span>Doctors</span>
              </Link>
            </li>
            <li className={splitLocation[1] === "SupportMaster" || splitLocation[1] === "SupportAdd" ? "active" : ""} onClick={handleLiClick}>
              <Link to={"/SupportMaster"} className="waves-effect">
                <i className="fa fa-comment-dots" /> <span>Support</span>
              </Link>
            </li>
            <li className={splitLocation[1] === "downloadrequest" ? "active" : ""} onClick={handleLiClick}>
              <Link to={"/downloadrequest"} className="waves-effect">
                <i className="fa fa-download" /> <span>Download Request</span>
              </Link>
            </li>

            <li>
              <a href="#" className="waves-effect">
                <i className="fa fa-layer-group" />
                <span>Master</span> <i className="fa fa-angle-left pull-right" />
              </a>
              <ul className="sidebar-submenu">
                <li onClick={handleLiClick} className={splitLocation[1] === "SpecializationMaster" || splitLocation[1] === "SpecializationAdd" ? "active" : ""}><Link to={"/SpecializationMaster"}><i className="fa fa-dot-circle" />Specialization</Link></li>
                <li onClick={handleLiClick} className={splitLocation[1] === "QualificationMaster" || splitLocation[1] === "QualificationAdd" ? "active" : ""}><Link to={"/QualificationMaster"}><i className="fa fa-dot-circle" />Qualification</Link></li>
                <li onClick={handleLiClick} className={splitLocation[1] === "CMSMaster" || splitLocation[1] === "CMSAdd" ? "active" : ""}><Link to={"/CMSMaster"}><i className="fa fa-dot-circle" />CMS</Link></li>
              </ul>
            </li>
          </>}
      </ul>
    </div>
  );
}

export default Sidebar;