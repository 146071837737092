import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
//import DataTable from 'react-data-table-component';
//import { DataTable } from 'primereact/datatable';
//import { Column } from 'primereact/column';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { Post } from '../../common/ajax';
import { immediateToast } from 'izitoast-react';
import { Modal, Button } from "react-bootstrap";
import Dropdown from 'react-bootstrap/Dropdown';
import * as Yup from "yup";
import moment from "moment";
import { AppointmentStatus } from '../enum';
import { Link,  useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';

const validationSchema = Yup.object().shape({
    reason: Yup.string().required('Reject reason is required'),
});

const Appointment = () => {
    const location = useLocation();
    const [filterStatus, setFilterStatus] = useState('');
    const [data, setData] = useState([]);
    const [rejectDoc, setRejectDoc] = useState(false);
    const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
    let role = JSON.parse(window.localStorage.getItem("AdminRole"));
    const doctorId = JSON.parse(window.localStorage.getItem("AdminUser"))
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [dateShow, setdateShow] = useState(false);
    const [initValue, setinitValue] = useState({
        reason: "",
    });
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        appointment: { value: null, matchMode: FilterMatchMode.CONTAINS },
        doctor: { value: null, matchMode: FilterMatchMode.CONTAINS },
        patient: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Doctor: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Patient: { value: null, matchMode: FilterMatchMode.CONTAINS },
        conversationType: { value: null, matchMode: FilterMatchMode.CONTAINS },
        paymentAmount: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.CONTAINS },
        reason: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const clear = () => {
        setinitValue({
            reason: "",
        });
    }
    const showTost = (msg, theme) => {
        theme = theme || "info";
        immediateToast(theme, {
            message: msg,
            timeout: 3000,
            position: "topRight"
        });
    }

    // const columns = [`
    //     {
    //         name: '',
    //         cell: row => <> {role == "Doctor" && (row.status == 'Rejected' || row.status == 'Cancelled') ? <span className='text-danger'>{row.status}</span> :
    //             <Dropdown>
    //                 <Dropdown.Toggle variant="success" size="sm" drop="end" id="dropdown-basic">
    //                     <i className='fa fa-cog'></i>
    //                 </Dropdown.Toggle>
    //                 <Dropdown.Menu>
    //                     {role == "Doctor" ? "" :
    //                         <Dropdown.Item href={`/AppointmentAdd/${row._id}`}>
    //                             Edit
    //                         </Dropdown.Item>}
    //                     {row.status == "Completed" ? <>
    //                         <Dropdown.Item href='#' onClick={() => handleDownloadRequest(row._id, 'Doctor')}>
    //                             Download Request By Doctor
    //                         </Dropdown.Item>
    //                         {role == "Doctor" ? "" :
    //                             <Dropdown.Item href='#' onClick={() => handleDownloadRequest(row._id, 'Patient')}>
    //                                 Download Request By Patient
    //                             </Dropdown.Item>}
    //                     </> : ""}
    //                     {role == "Doctor" ? row.status == "Accepted" ? <>
    //                         <Dropdown.Item href='#' onClick={() => handleCompletedAppointment(row._id)}>
    //                             Completed
    //                         </Dropdown.Item>
    //                         {row.conversationType == "Chat" ?
    //                             <Dropdown.Item href={`/chat/${row._id}`}>
    //                                 Chat
    //                             </Dropdown.Item> : ""}</> : "" : ""}
    //                     {row.status == "New" ? <>
    //                         <Dropdown.Item href='#' onClick={() => handleApproveReject(row._id, 'Accepted')}>
    //                             Accept
    //                         </Dropdown.Item>
    //                         <Dropdown.Item href='#' onClick={() => {
    //                             if (row.status !== 'Rejected') {
    //                                 setRejectDoc(true);
    //                                 setSelectedAppointmentId(row._id);
    //                             } else {
    //                                 showTost("This appointment has already been rejected.", "warning");
    //                             }
    //                         }}
    //                             className={` ${row.status === 'Rejected' ? 'text-secondary' : 'text-danger'}`}
    //                             disabled={row.status === 'Rejected'}>
    //                             Reject
    //                         </Dropdown.Item>
    //                     </> : ""}
    //                 </Dropdown.Menu>
    //             </Dropdown>
    //         }</>,
    //         sortable: false,
    //     },
    //     {
    //         name: row => 'Appointment Time',
    //         selector: row => `${moment(row.appointmentDate).format('DD-MM-YYYY')} ${moment(row.appointmentTime, 'HH:mm').format('h:mm A')}`,
    //         sortable: true,
    //         width: '190px',

    //     },
    //     role !== 'Doctor' && {
    //         name: 'Doctor',
    //         selector: row => `${row.doctorId?.firstName} ${row.doctorId?.middleName} ${row.doctorId?.lastName}`,
    //         sortable: true,

    //     },
    //     {
    //         name: 'Patient',
    //         selector: row => `${row.patientId?.firstName} ${row.patientId?.middleName} ${row.patientId?.lastName}`,
    //         sortable: true,

    //     },
    //     {
    //         name: 'Mode',
    //         selector: row => row.conversationType,
    //         sortable: true,

    //     },
    //     {
    //         name: 'Payment',
    //         selector: row => row.paymentAmount,
    //         sortable: true,

    //     },
    //     {
    //         name: 'Type',
    //         selector: row => row.status,
    //         sortable: true,

    //     },
    //     {
    //         name: 'Outcome',
    //         selector: row => row.reason,
    //         sortable: false,

    //     }
    // ]

    const getPost = async () => {
        if (role == "Doctor") {
            Post(`appointment/getAppointmentByDoctorId/${doctorId}`, { "filterStatus": filterStatus, "startDate": startDate, "endDate": endDate })
                .then(async (res) => {
                    if (res.success) {
                        await res.data.map((row, i) => {
                            var appointment = moment(row.appointmentDate).format('DD-MM-YYYY') + " " + moment(row.appointmentTime, 'HH:mm').format('h:mm A');
                            var doctor = row.doctorId ? row.doctorId?.firstName + " " + row.doctorId?.middleName + " " + row.doctorId?.lastName : "";
                            var patient = row.patientId ? row.patientId?.firstName + " " + row.patientId?.middleName + " " + row.patientId?.lastName : "";
                            row.patient = patient;
                            row.doctor = doctor;
                            row.appointment = appointment;
                        })
                        //debugger;
                        setData(res.data);
                    } else {
                        showTost(res.message, "warning");
                    }
                })
                .catch((err) => {
                    showTost("Error getting in appointment of doctor" + err.message , "warning");
                });
        }
        else {
            Post(`appointment/getAllAppoitment`, { "filterStatus": filterStatus, "startDate": startDate, "endDate": endDate })
                .then(async (res) => {
                    if (res.success) {

                        await res.data.map((row, i) => {
                            var appointment = moment(row.appointmentDate).format('DD-MM-YYYY') + " " + moment(row.appointmentTime, 'HH:mm').format('h:mm A');
                            var doctor = row.doctorId ? row.doctorId?.firstName + " " + row.doctorId?.middleName + " " + row.doctorId?.lastName : "";
                            var patient = row.patientId ? row.patientId?.firstName + " " + row.patientId?.middleName + " " + row.patientId?.lastName : "";
                            row.patient = patient;
                            row.doctor = doctor;
                            row.appointment = appointment;
                        })
                        //debugger;
                        setData(res.data);
                    }
                    else {
                        showTost(res.message, "warning");
                    }
                })
                .catch((err) => {
                    showTost("Error getting in Appointment List" + err.message , "warning");
                });
        }
    }

    const setDayFilter = (val) => {
        setdateShow(false);
        if (val == "This Month") {
            handleThisMonth();
        }
        else if (val == "Last Month") {
            handleLastMonth();
        }
        else if (val == "Custom") {
            setdateShow(true);
        }
        else {
            setDateRange([null, null]);
        }


    }
    const handleThisMonth = () => {
        const startOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        const endOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
        startOfMonth.setHours(0, 0, 0, 0);
        endOfMonth.setHours(23, 59, 59, 999);

        setDateRange([startOfMonth, endOfMonth]);
    };

    const handleLastMonth = () => {
        const startOfLastMonth = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
        const endOfLastMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 0);
        startOfLastMonth.setHours(0, 0, 0, 0);
        endOfLastMonth.setHours(23, 59, 59, 999);

        setDateRange([startOfLastMonth, endOfLastMonth]);
    };
    const handleApproveReject = async (id, action, reason = '') => {
        try {
            let canceledBy = role == "Doctor" ? "Doctor" : "Admin";
            const res = await Post('appointment/doctorAcceptRejectAppointment', {
                id,
                status: action,
                reason: reason,
                canceledBy: canceledBy
            });
            if (res.success) {
                showTost(`Request ${action}ed successfully`, "success");
                getPost(true);
                clear();
            } else {
                showTost(res.message, "warning");
            }
        } catch (err) {
            showTost("Error getting when updating request status" + err.message , "warning");
        }
    };
    const handleDownloadRequest = async (id, created) => {
        try {
            const res = await Post('downloadreq/addDownloadRequest', {
                "appointmentId": id,
                "requestedBy": created
            });
            if (res._id) {
                showTost("Download Request added for this Appointment", "success");
            }
        } catch (err) {
            showTost("Error getting when updating request status" + err.message , "warning");
        }
    };
    const handleCompletedAppointment = async (id) => {
        Post(`appointment/ComplateAppointmentbyDoctor`, { "id": id })
            .then((res) => {
                if (res.success) {
                    showTost("Appointment Completed successfully", "success");
                    getPost();
                } else {
                    showTost(res.msg, "warning")
                }
            })
    }
    useEffect(() => {
        if (location.state) {
            setFilterStatus(location.state);
        }
    }, []);
    useEffect(() => {
        getPost();
    }, [filterStatus, startDate, endDate]);
    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </IconField>
            </div>
        );
    };
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const header = renderHeader();
    const verifiedBodyTemplate = (row) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant="success" size="sm" drop="end" id="dropdown-basic">
                    <i className='fa fa-cog'></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {role == "Doctor" ? "" :
                        <Dropdown.Item href={`/AppointmentAdd/${row._id}`}>
                            Edit
                        </Dropdown.Item>}
                    {row.status == "Completed" ? <>
                        <Dropdown.Item href='#' onClick={() => handleDownloadRequest(row._id, 'Doctor')}>
                            Download Request By Doctor
                        </Dropdown.Item>
                        {role == "Doctor" ? "" :
                            <Dropdown.Item href='#' onClick={() => handleDownloadRequest(row._id, 'Patient')}>
                                Download Request By Patient
                            </Dropdown.Item>}
                    </> : ""}
                    {role == "Doctor" ? row.status == "Accepted" ? <>
                        <Dropdown.Item href='#' onClick={() => handleCompletedAppointment(row._id)}>
                            Completed
                        </Dropdown.Item>
                        {row.conversationType == "Chat" ?
                            <Dropdown.Item href={`/chat/${row._id}`}>
                                Chat
                            </Dropdown.Item> : ""}</> : "" : ""}
                    {row.status == "New" ? <>
                        <Dropdown.Item href='#' onClick={() => handleApproveReject(row._id, 'Accepted')}>
                            Accept
                        </Dropdown.Item>
                        <Dropdown.Item href='#' onClick={() => {
                            if (row.status !== 'Rejected') {
                                setRejectDoc(true);
                                setSelectedAppointmentId(row._id);
                            } else {
                                showTost("This appointment has already been rejected.", "warning");
                            }
                        }}
                            className={` ${row.status === 'Rejected' ? 'text-secondary' : 'text-danger'}`}
                            disabled={row.status === 'Rejected'}>
                            Reject
                        </Dropdown.Item>
                    </> : ""}
                </Dropdown.Menu>
            </Dropdown>
        )
    }
    return (
        <div className="container-fluid">
            <div className="row py-2 align-items-center">
                <div className="col-md-3">
                    <h4 className="page-title">Appointment</h4>
                </div>
                <div className="col-md-9 d-flex justify-content-md-end align-items-center flex-wrap">
                    <div className="btn-group ml-2 mb-2 mb-sm-0">
                        <select className='form-control' onChange={(e) => { setFilterStatus(e.target.value); }} value={filterStatus}>
                            <option value="">All</option>
                            {AppointmentStatus.map((x, i) => (
                                <option key={i} value={x}>
                                    {x}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="btn-group ml-2 mb-2 mb-sm-0" style={{display: dateShow ? "" : "none" }}>
                        <DatePicker
                            selected={startDate}
                            onChange={(update) => {
                                if (update && update[0] && update[1]) {
                                    const updatedStartDate = new Date(update[0]);
                                    const updatedEndDate = new Date(update[1]);
                                    updatedStartDate.setHours(0, 0, 0, 0);
                                    updatedEndDate.setHours(23, 59, 59, 999);
                                    setDateRange([updatedStartDate, updatedEndDate]);
                                } else {
                                    setDateRange(update);
                                }
                            }}
                            startDate={startDate}
                            endDate={endDate}
                            selectsRange
                            isClearable={true}
                            className="form-control datepickerStyle"
                            placeholderText="Select Start to End Date Range"
                        />
                    </div>
                    <div className="btn-group ml-2 mb-2 mb-sm-0">
                        <select className='form-control' onChange={(e) => { setDayFilter(e.target.value); }} >
                            <option value="">All Days</option>
                            <option value="This Month">This Month</option>
                            <option value="Last Month">Last Month</option>
                            <option value="Custom">Custom</option>
                        </select>
                    </div>
                    {role == "Doctor" ? "" :
                        <div className='ml-2 mb-2 mb-sm-0'>
                            <Link to={"/AppointmentAdd"}>
                                <button type="button" className="btn btn-light waves-effect waves-light">
                                    <i className="fa fa-plus mr-1" /> Add Appointment
                                </button>
                            </Link>
                        </div>
                    }
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-lg-12">
                    {/* <DataTable
                        columns={columns}
                        data={data}
                    /> */}
                    <DataTable value={data} paginator rows={10} dataKey="_id" filters={filters} filterDisplay="row"
                        globalFilterFields={['appointment', 'doctor', 'patient', 'conversationType', 'paymentAmount', 'status', 'reason']} header={header} emptyMessage="No Appointment found.">
                        <Column field="" header="" style={{ minWidth: '6rem' }} body={verifiedBodyTemplate} />
                        <Column field="appointment" header="Appointment" showFilterMenu={false} filter style={{ minWidth: '12rem' }} sortable />
                        <Column header="Doctor" field="doctor" style={{ minWidth: '12rem' }} showFilterMenu={false} filter  sortable />
                        <Column header="Patient" field="patient" showFilterMenu={false} filter filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '14rem' }} sortable />
                        <Column field="conversationType" header="Mode" showFilterMenu={false} filterMenuStyle={{ width: '8rem' }} style={{ minWidth: '8rem' }} filter sortable />
                        <Column field="paymentAmount" header="Payment" showFilterMenu={false} style={{ minWidth: '6rem' }} filter sortable />
                        <Column field="status" header="Type" showFilterMenu={false} style={{ minWidth: '6rem' }} filter sortable />
                        <Column field="reason" header="Outcome" showFilterMenu={false} style={{ minWidth: '6rem' }} filter sortable />
                    </DataTable>
                </div>
            </div>

            <Modal show={rejectDoc} onHide={() => setRejectDoc(false)}>
                <Modal.Header>
                    <label style={{ fontSize: "16px", textAlign: "center" }} className='text-align-center'>Status</label>
                    <button type="button" className="close" onClick={() => setRejectDoc(false)}>&times;</button>
                </Modal.Header>
                <Modal.Body className='px-4'>
                    <Formik
                        initialValues={initValue}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            handleApproveReject(selectedAppointmentId, 'Rejected', values.reason)
                            setRejectDoc(false);
                        }
                        }
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            values,
                            touched,
                            errors
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <div className='mt-2'>
                                    <label>Reject Reason</label>
                                    <Field
                                        as="textarea"
                                        name="reason"
                                        placeholder='Enter Reject Reason'
                                        className={`form-control ${touched.reason && errors.reason ? 'is-invalid' : ''}`}
                                    />
                                    <ErrorMessage name="reason" component="div" className="text-danger" />
                                </div>
                                <div className='mt-2'>
                                    <Button variant="success" type="submit" className='mr-2'>
                                        <i className="fa fa-check" /> Submit
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Appointment
