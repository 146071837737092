import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Post, Get } from '../../common/ajax';
import { immediateToast } from 'izitoast-react';
import Dropdown from 'react-bootstrap/Dropdown';
import DialogBox from "../../common/DialogBox";
import parse from 'html-react-parser';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';

const Support = () => {
    const [data, setData] = useState([]);
    const [showDialogBox, setDialogBox] = useState(false);
    const [dialogMessage, setdialogMessage] = useState("");
    const [rowId, setRowId] = useState("");
    const [newStatus, setnewStatus] = useState(false);
    let role = JSON.parse(window.localStorage.getItem("AdminRole"));
    const [filterStatus, setFilterStatus] = useState('');
    const statusList = ["Pending", "InProgress", "Closed", "Resolved"];

    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        TicketNo: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        ResolveNote: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Status: { value: null, matchMode: FilterMatchMode.CONTAINS },
        addedBY: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    useEffect(() => {
        getPost();
    }, []);
    useEffect(() => {
        getPost();
    }, [filterStatus]);
    const showTost = (msg, theme) => {
        theme = theme || "info";
        immediateToast(theme, {
            message: msg,
            timeout: 3000,
            position: "topRight"
        });
    }

    const getPost = async () => {
        Post(`supports/getSupports`, { "status": filterStatus })
            .then(async (res) => {
                if (res.success) {
                    await res.data.map((row, i) => {
                        row.addedBY = row.AddedByDoctor ? row.AddedByDoctor.firstName + " " + row.AddedByDoctor.lastName : 
                        row.AddedByPatient ? row.AddedByPatient.firstName + " " + row.AddedByPatient.lastName : "";
                    })
                    setData(res.data);
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error getting in support list" + err.message , "warning");
            });
    }
    const showStatusDiaglog = (rowId, status) => {
        setRowId(rowId);
        setnewStatus(status);
        setdialogMessage("Do you want to move to " + status + "?");
        setDialogBox(true);
    }

    const changeStatus = async (id, active) => {
        Post(`supports/changeSupportStatus`, { id: rowId, status: newStatus })
            .then((res) => {
                if (res.success) {
                    getPost();
                    setDialogBox(false);
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error getting when suport status change" + err.message , "warning");
            });
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </IconField>
            </div>
        );
    };
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const header = renderHeader();
    const verifiedBodyTemplate = (m) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant="success" size="sm" drop="end" id="dropdown-basic">
                    <i className='fa fa-cog'></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item href={`/SupportAdd/${m._id}`}>Edit</Dropdown.Item>
                    {m.ResolveNote && (
                        <Dropdown.Item href={`/SupportAdd/${m._id}`} >View resolved Data</Dropdown.Item>
                    )}
                    {m.Status != "Pending" &&
                        <Dropdown.Item href='#' onClick={(e) => { showStatusDiaglog(m._id, "Pending") }} >Set As Pending</Dropdown.Item>
                    }
                    {m.Status != "InProgress" &&
                        <Dropdown.Item href='#' onClick={(e) => { showStatusDiaglog(m._id, "InProgress") }} >Set As InProgress</Dropdown.Item>
                    }
                    {m.Status != "Closed" &&

                        <Dropdown.Item href='#' onClick={(e) => { showStatusDiaglog(m._id, "Closed") }} >Set As Closed</Dropdown.Item>
                    }
                </Dropdown.Menu>
            </Dropdown>
        )
    }
    const parseDescription=(m)=>{
        return(parse(m.Description))
    }
    const parseResolveNote=(m)=>{
        return(parse(m.ResolveNote))
    }
    return (
        <div className="container-fluid">
            <div className="row py-2 align-items-center">
                <div className="col-lg-7 col-md-6 col-sm-5 mb-2 mb-sm-0">
                    <h4 className="page-title">Support Master</h4>
                </div>
                <div className="col-lg-5 col-md-6 col-sm-7">
                    <div className='d-flex justify-content-sm-end align-items-center flex-wrap'>
                        <div className="btn-group mr-2 mb-2 mb-md-0">
                            {role == "Doctor" ? "" :
                                <select className='form-control' onChange={(e) => { setFilterStatus(e.target.value); }} value={filterStatus}>
                                    <option value="">All</option>
                                    {statusList.map((status) => {
                                        return (
                                            <option value={status}>{status}</option>
                                        )
                                    })}
                                </select>}
                        </div>
                        <div className="btn-group mb-2 mb-md-0">
                            <Link to={"/SupportAdd"}><button type="button" className="btn btn-light waves-effect waves-light"><i className="fa fa-plus mr-1" /> Add Support</button></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable value={data} paginator rows={10} dataKey="_id" filters={filters} filterDisplay="row"
                                    globalFilterFields={['appointment', 'doctor', 'patient', 'conversationType', 'paymentAmount', 'status', 'reason']} header={header} emptyMessage="No Appointment found.">
                                    <Column field="" header="" style={{ minWidth: '6rem' }} body={verifiedBodyTemplate} />
                                    <Column field="TicketNo" header="Ticket No" style={{ minWidth: '12rem' }} showFilterMenu={false} filter sortable />
                                    <Column field="Title" header="Title" showFilterMenu={false} filter filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '14rem' }} sortable />
                                    <Column body={parseDescription} field="Description" header="Description" showFilterMenu={false} filterMenuStyle={{ width: '8rem' }} style={{ minWidth: '8rem' }} filter sortable />
                                    <Column body={parseResolveNote} field="ResolveNote" header="Resolve Note" showFilterMenu={false} filterMenuStyle={{ width: '8rem' }} style={{ minWidth: '8rem' }} filter sortable />
                                    <Column field="Status" header="Status" showFilterMenu={false} filterMenuStyle={{ width: '8rem' }} style={{ minWidth: '8rem' }} filter sortable />
                                    {role !== "Doctor" &&
                                        <Column field="addedBY" header="Added By" showFilterMenu={false} filterMenuStyle={{ width: '8rem' }} style={{ minWidth: '8rem' }} filter sortable />
                                    }
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DialogBox show={showDialogBox} okClick={changeStatus} close={() => { setDialogBox(false); setRowId("") }} message={dialogMessage}></DialogBox>
        </div>
    )
}
export default Support;