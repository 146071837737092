import React, { useEffect, useState } from 'react';
import { Formik, } from "formik";
import { Post, Get } from '../../common/ajax';
import { Form, Button } from "react-bootstrap";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { immediateToast } from 'izitoast-react';
import * as Yup from "yup";
import moment from "moment";
import Swal from "sweetalert2";
import { AppointmentStatus, ConversationTypeStatus } from '../enum';

const validation = Yup.object().shape({
    appointmentDate: Yup.string().required(),
    appointmentTime: Yup.string().required(),
    doctorId: Yup.string().required(),
    patientId: Yup.string().required(),
    conversationType: Yup.string().required()
});
const AppointmentAdd = () => {
    let params = useParams();
    const navigate = useNavigate();
    const [formkey, setFormKey] = useState(Math.random() * 1000000);
    const [initValue, setinitValue] = useState({
        doctorId: "",
        patientId: "",
        paymentAmount: 0,
        appointmentDate: "",
        appointmentTime: "",
        status: "",
        reason: "",
        conversationType: "",
        specializationId: ""
    });
    const clear = () => {
        setinitValue({
            doctorId: "",
            patientId: "",
            paymentAmount: 0,
            appointmentDate: "",
            appointmentTime: "",
            status: "",
            reason: "",
            conversationType: "",
            specializationId: ""
        });
    }
    const [drData, setDrData] = useState([]);
    const [patientData, setPatientData] = useState([]);
    const [specialization, setSpecialization] = useState([]);
    const [minDate, setMinDate] = useState('');
    useEffect(() => {
        if (params.id) {
            getById();
        }
        //getDoctors(true);
        getPatients(true);
        getSpecialization(true);
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const formattedDate = tomorrow.toISOString().split('T')[0];
        setMinDate(formattedDate);
    }, []);
    const showTost = (msg, theme) => {
        theme = theme || "info";
        immediateToast(theme, {
            message: msg,
            timeout: 3000,
            position: "topRight"
        });
    }
    const clearData = () => {
        navigate('/appointment');
    }
    const [msgtitle, setmsgtitle] = useState("");
    const [availability, setAvailability] = useState([]);
    const [availableApptTypes, setAvailableApptTypes] = useState([]);
    const Checkavailability = async (doctorId, appointmentDate) => {
        if (doctorId && appointmentDate) {
            try {
                Post(`doctors/Checkavailability`, { doctorId: doctorId, appointmentdate: appointmentDate })
                    .then((res) => {
                        if (!res.success) {
                            showTost(res.message);
                        } else {
                            if (!params.id) {
                                setmsgtitle(res.data?.message);
                            }
                            else {
                                setmsgtitle("");
                            }
                            if (params.id) {
                                setAvailability(res.data?.Timeslots);
                            }
                            else {
                                setAvailability(res.data?.filteredTimeslots);
                            }
                        }
                    })
            } catch (err) {
                showTost("Error while checking availability" + err.message , "error");
            }
        }
    };
    const getDoctorById = async (doctorId, type, setFieldValue) => {
        if (doctorId) {
            Get(`doctors/getDoctorById/${doctorId}`)
                .then((res) => {
                    if (res.success) {
                        if (type) {
                            const hourPayment = res.data.paymentId?.payment?.find(p => p.period == "Hour");
                            if (hourPayment) {
                                const voiceAmount = hourPayment.typeamount.find(t => t.conversationType == type);
                                if (voiceAmount) {
                                    setFieldValue('paymentAmount', voiceAmount.amount || 0);
                                    return;
                                }
                            }
                        }
                        setFieldValue('paymentAmount', 0);
                    }
                    else{
                        showTost(res.data.message , "warning");
                        setFieldValue('paymentAmount', 0);
                    }
                })
                .catch((err) => {
                    showTost("Error in getting Doctor Detail." + err.message , "warning");
                    setFieldValue('paymentAmount', 0);
                });
        }
        else {
            setFieldValue('paymentAmount', 0);
        }
    };
    const handleDoctorChange = (e, setFieldValue, values) => {
        const doctorId = e.target.value;
        setFieldValue('doctorId', doctorId);
        if (doctorId) {
            getDoctorById(doctorId, values.conversationType, setFieldValue)
            Checkavailability(doctorId, values.appointmentDate);
        }
    };
    const handleDateChange = (e, setFieldValue, values) => {
        const appointmentDate = e.target.value;
        setFieldValue('appointmentDate', appointmentDate);
        Checkavailability(values.doctorId, appointmentDate);
    };
    const getDoctors = async (special) => {
        if(!special)return;
        Post(`doctors/getDoctors`,{active:true,special:special})
            .then((res) => {
                if (res.success) {
                    setDrData(res.data);
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error in getting Doctor List" + err.message , "warning");
            });
    }
    const getPatients = async (active) => {
        Post(`patients/getPatients`,{active:active})
            .then((res) => {
                if (res.success) {
                    setPatientData(res.data);
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error in getting Patient List" + err.message , "warning");
            });
    }
    const getSpecialization = async (active) => {
        Get(`masters/getSpecialization/${active}`)
            .then((res) => {
                if (res.success) {
                    setSpecialization(res.data);
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error in getting Specialization List" + err.message, "warning");
            });
    }
    const handleSubmit = async (value) => {
        let val = { ...value };
        val.addedBy = "Admin";
        Post(`appointment/addAppointment`, val)
            .then((res) => {
                if (res.success == false) {
                    showTost(res.message);
                    return false;
                }
                else {
                    showTost(res.message);
                    navigate('/appointment');
                }
            })
            .catch((err) => {
                showTost("Error in getting when Appointment data save" + err.message , "warning");
            });
    };
    const getById = async () => {
        Get(`appointment/getAppointmentById/${params.id}`)
            .then((res) => {
                const appointmentDate = moment(res.data.appointmentDate).format('YYYY-MM-DD');
                Checkavailability(res.data.doctorId?._id, appointmentDate);
                getDoctors(res.data.specializationId);
                setAvailableApptTypes(ConversationTypeStatus);
                setinitValue({
                    id: params.id,
                    appointmentDate: appointmentDate,
                    paymentAmount: res.data.paymentAmount,
                    appointmentTime: res.data.appointmentTime,
                    specializationId: res.data.specializationId,
                    doctorId: res.data.doctorId?._id,
                    patientId: res.data.patientId?._id,
                    status: res.data.status,
                    conversationType: res.data.conversationType
                });
                setFormKey(Math.random() * 1000000);
            })
            .catch((err) => {
                showTost("Error getting in appointment detail  " + err.message , "error");
            });
    }
    const handleEdit = async (val) => {
        val.id = params.id;
        val.addedBy = "Admin";
        await Post(`appointment/editAppointment`, val)
            .then((res) => {
                if (res.success == true) {
                    clear();
                    Swal.fire({
                        title: "Your work has been saved",
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: "#FD711A",
                    }).then(async ({ isConfirmed }) => {
                        if (isConfirmed) {
                            navigate('/appointment');
                        }
                    });
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error getting when appointment data update" + err.message , "error");
            });
    }
    const handleAppointmentTimeChange = (e) => {
        const selectedTime = e.target.value;

        const selectedSlot = availability.find(slot => slot.From === selectedTime);

        if (selectedSlot) {
            setAvailableApptTypes(selectedSlot.apptType);
        } else {
            setAvailableApptTypes([]);
        }
    };
    return (
        <div className="container-fluid">
            <Formik
                enableReinitialize={true}
                onSubmit={params.id ? handleEdit : handleSubmit}
                initialValues={initValue}
                validationSchema={validation}
                key={formkey}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    setFieldValue,

                }) => {
                    return (<>
                        <div className="row pt-2 pb-2">
                            <div className="col-sm-10">
                                <h4 className="page-title">{params.id ? "Edit Appointment" : "Add Appointment"}</h4>
                            </div>
                            <div className="col-sm-2">
                                <div className="btn-group float-sm-right">
                                    <Link to={"/appointment"}><button type="button" className="btn btn-light waves-effect waves-light"><i className="fa fa-bars mr-1" /> View Appointments</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <Form
                                            onSubmit={handleSubmit}
                                            onChange={handleChange}>
                                            <div className='row mb-3'>
                                                <div className=' col-lg-6 col-xl-6 col-12 mt-2'>
                                                    <label>Select Specialization Of Doctor</label>
                                                    <select name="specializationId" value={values.specializationId} className={!!touched.specializationId && !!errors.specializationId ? "form-control is-invalid" : "form-control"} onChange={(e)=>{getDoctors(e.target.value); setFieldValue('doctorId', '');}}>
                                                        <option value="">Select Specialization</option>
                                                        {specialization
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.name}</option>
                                                            ))}
                                                    </select>
                                                </div>
                                                <div className=' col-lg-6 col-xl-6 col-12 mt-2'>
                                                    <label>Select Doctor</label>
                                                    <select name="doctorId" value={values.doctorId}

                                                        onChange={(e) => handleDoctorChange(e, setFieldValue, values)}
                                                        className={!!touched.doctorId && !!errors.doctorId ? "form-control is-invalid" : "form-control"}>
                                                        <option value="">Select Doctor</option>
                                                        {drData
                                                            .filter((doctor) =>
                                                                doctor.specialization.some(
                                                                    (spec) => spec._id === values.specializationId
                                                                )
                                                            )
                                                            .map((doctor, i) => (
                                                                <option key={i} value={doctor._id}>
                                                                    {doctor.firstName} {doctor.middleName} {doctor.lastName}
                                                                </option>
                                                            ))}
                                                    </select>
                                                    <span className='text-danger'></span>
                                                </div>
                                                <div className=' col-lg-6 col-xl-6 col-12 mt-2'>
                                                    <label>Appointment Date</label>
                                                    <input type='date' name='appointmentDate' value={values.appointmentDate}

                                                        className={!!touched.appointmentDate && !!errors.appointmentDate ? "form-control is-invalid" : "form-control"}
                                                        onChange={(e) => handleDateChange(e, setFieldValue, values)} min={minDate} />
                                                    <span className='text-danger'>{msgtitle}</span>
                                                </div>
                                                <div className=' col-lg-6 col-xl-6 col-12 mt-2'>
                                                    <label>Appointment Time</label>
                                                    <select
                                                        name="appointmentTime"

                                                        value={values.appointmentTime}
                                                        className={!!touched.appointmentTime && !!errors.appointmentTime ? "form-control is-invalid" : "form-control"}
                                                        onChange={(e) => { handleAppointmentTimeChange(e); handleChange(e) }}>
                                                        <option value="">Select Appointment Time</option>
                                                        {availability.map((slot, i) => (
                                                            <option key={i} value={slot.From}>
                                                                {`${slot.From} - ${slot.To}`}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>

                                                <div className=' col-lg-6 col-xl-6 col-12 mt-2'>
                                                    <label>Select Patient</label>
                                                    <select name="patientId" value={values.patientId} className={!!touched.patientId && !!errors.patientId ? "form-control is-invalid" : "form-control"}>
                                                        <option value="">Select Patient</option>
                                                        {patientData
                                                            .map((x, i) => (
                                                                <option key={i} value={x._id}>{x.firstName} {x.middleName} {x.lastName}</option>
                                                            ))}
                                                    </select>
                                                </div>
                                                {params.id ?
                                                    <>
                                                        <div className=' col-lg-6 col-xl-6 col-12 mt-2'>
                                                            <label>Select Status</label>
                                                            <select name="status" value={values.status} className={!!touched.status && !!errors.status ? "form-control is-invalid" : "form-control"}>
                                                                <option value="">Select Status</option>
                                                                {AppointmentStatus.map((x, i) => (
                                                                    <option key={i} value={x}>
                                                                        {x}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </> : ""}
                                                <div className=' col-lg-6 col-xl-6 col-12 mt-2'>
                                                    <label>Select Conversation Type</label>
                                                    <select name="conversationType" onChange={(e) => getDoctorById(values.doctorId, e.target.value, setFieldValue, values)} value={values.conversationType} className={!!touched.conversationType && !!errors.conversationType ? "form-control is-invalid" : "form-control"}>
                                                        <option value="">Select Conversation Type</option>
                                                        {availableApptTypes.map((type, i) => (
                                                            <option key={i} value={type}>
                                                                {type}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className=' col-lg-6 col-xl-6 col-12 mt-2'>
                                                    <label>Payment</label>
                                                    <div>{values.paymentAmount} RS.</div>
                                                </div>
                                            </div>
                                            <div className="col-12 pr-0">
                                                <Button type="reset" className="btn btn-danger waves-effect waves-light float-right ml-2" onClick={() => clearData()}>Cancel</Button>
                                                <Button type="submit" className={(params.id ? "btn-success" : "btn-light") + " btn waves-effect waves-light float-right"}>{params.id ? "Update" : "Add New"}</Button>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    );
                }}
            </Formik>
        </div>
    )
}

export default AppointmentAdd
