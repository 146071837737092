import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Formik, Field, ErrorMessage } from "formik";
import { Post, Get } from '../../common/ajax';
import { Form, Button } from "react-bootstrap";
import { immediateToast } from 'izitoast-react';
import * as Yup from "yup";
import CKEditor from "react-ckeditor-component";

const addDataSchema = Yup.object().shape({
    Title: Yup.string().required("Support Title field is required"),
    Description: Yup.string().required("Support Details field is required")
});

const SupportAdd = () => {
    let params = useParams();
    const navigate = useNavigate();
    const statusList = ["Pending", "InProgress", "Closed", "Resolved"];
    const [initValue, setValue] = useState({
        id: "",
        TicketNo: "",
        Title: "",
        Description: "",
        ResolveNote: "",
        Status: "Pending",
        is_active: true,
    });

    const clearData = () => {
        navigate('/SupportMaster');
    }
    useEffect(() => {
        if (params.id) {
            getPost();
        }
        else {
            getNewTicketNo();
        }
    }, [params.id]);


    const getPost = async () => {
        Get(`supports/getSupportById/${params.id}`)
            .then((res) => {
                if (res.data) {
                    setresponcevalue(res.data,);
                }
            })
            .catch((err) => {
                showTost("Error getting in support details" + err.message , "warning");
            });
    }
    const setresponcevalue = async (res,) => {
        setValue({
            id: params.id,
            TicketNo: res.TicketNo,
            Title: res.Title,
            Description: res.Description,
            ResolveNote: res.ResolveNote,
            Status: res.Status,
        });
    }

    const showTost = (msg, theme) => {
        theme = theme || "info";
        immediateToast(theme, {
            message: msg,
            timeout: 3000,
            position: "topRight"
        });
    }

    const getNewTicketNo = async () => {
        Get(`supports/getNewTicketNo`)
            .then((res) => {
                if (res.success) {
                    setValue(initValue => ({ ...initValue, TicketNo: res.data }));
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error getting in new ticket no of support." + err.message , "warning");
            });
    }

    const handleSubmit = async (value) => {
        let val = { ...value };
        Post(`supports/addSupport`, val)
            .then((res) => {
                if (res.success == false) {
                    showTost(res.message);
                    return false;
                }
                else {
                    showTost(res.message);
                    navigate('/SupportMaster');
                }
            })
            .catch((err) => {
                showTost("Error getting when support data save." + err.message , "warning");
            });
    };


    return (
        <div className="container-fluid">
            <Formik
                enableReinitialize={true}
                onSubmit={handleSubmit}
                initialValues={initValue}
                validationSchema={addDataSchema}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    setFieldValue,
                }) => {
                    return (
                        <>
                            <div className="row pt-2 pb-2">
                                <div className="col-sm-10">
                                    <h4 className="page-title">{params.id ? "Edit Support" : "Add Support"}</h4>
                                </div>
                                <div className="col-sm-2">
                                    <div className="btn-group float-sm-right">
                                        <Link to={"/SupportMaster"}><button type="button" className="btn btn-light waves-effect waves-light"><i className="fa fa-bars mr-1" /> View Supports</button></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <Form id="textmasterform"
                                                onSubmit={handleSubmit}
                                                onChange={handleChange}>
                                                <div className="row">
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} /> Title:</label>
                                                            <Field value={values.Title} onChange={(e) => { values.Title = e.target.value }} type="text" name="title" className={`form-control ${touched.Title && errors.Title ? "is-invalid" : ""}`} />
                                                            <ErrorMessage name="title" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    {params.id &&
                                                        <div className="col-12 col-lg-4 col-xl-4 offset-2">
                                                            <div className="form-group" >
                                                                <label className="col-form-label">Status:</label>
                                                                <select className='form-control' name="Status" onChange={(e) => { values.Status = e.target.value }} value={values.Status}>
                                                                    {statusList.map((status) => {
                                                                        return (
                                                                            <option key={Math.random()} value={status}>{status}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    }
                                                    <br />
                                                    <div className="col-12 col-lg-9 col-xl-9">
                                                        <div className="form-group" >
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} /> Description:</label>
                                                            <CKEditor
                                                                name="Description"
                                                                id="Description"
                                                                content={values.Description}
                                                                events={{
                                                                    change: (eve) => {
                                                                        const data = eve.editor.getData();
                                                                        setFieldValue("Description", data);
                                                                    }
                                                                }}
                                                            />
                                                            <ErrorMessage name="Description" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    {params.id &&

                                                        <div className="col-12 col-lg-9 col-xl-9">
                                                            <div className="form-group" >
                                                                <label className="col-form-label">Resolve Note:</label>
                                                                <div>
                                                                    <CKEditor
                                                                        name="ResolveNote"
                                                                        id="ResolveNote"
                                                                        content={values.ResolveNote}
                                                                        events={{
                                                                            change: (eve) => {
                                                                                const data = eve.editor.getData();
                                                                                setFieldValue("ResolveNote", data);
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col-12">
                                                        <Button type="reset" className="btn btn-danger waves-effect waves-light float-right ml-2" onClick={() => clearData()}>Cancel</Button>
                                                        {!values.ResolveNote && (
                                                            <Button type="submit" className={(params.id ? "btn-success" : "btn-light") + " btn waves-effect waves-light float-right"}>{params.id ? "Update" : "Add New"}</Button>
                                                        )}
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                }}
            </Formik>
        </div>
    )
}
export default SupportAdd;