import React, { useEffect, useState } from 'react';
import axios from "axios";
import { PieChart, Pie, AreaChart, Area, Tooltip, XAxis, YAxis } from 'recharts';
import { Post, Get } from '../common/ajax';
import { Link, useNavigate } from 'react-router-dom';
import { immediateToast } from 'izitoast-react';

const Manatee = () => {
  const [data, setData] = useState({});
  let navigate = useNavigate();
  const showTost = (msg, theme) => {
    theme = theme || "info";
    immediateToast(theme, {
        message: msg,
        timeout: 3000,
        position: "topRight"
    });
}
  useEffect(() => {
    Get('masters/getdashboardcount')
      .then((res) => {
        if (res.data) {
          setData(res.data)
        }
      })
      .catch((err) => {
        showTost("Error in getting dashboard count." + err.message , "warning");
      });
  }, []);
  return <>
      <div className="container-fluid">
        <div className="row mt-3">
          <div className="col-12 col-lg-4 col-xl-3">
            <div className="card gradient-bloody">
              <div className="card-body">
                <div className="media align-items-center" onClick={() => navigate(`/PatientsMaster`)}>
                  <div className="w-icon"><i className="fa-solid fa-person text-white" /></div>
                  <div className="media-body ml-3 border-left-xs border-white-2">
                    <h4 className="mb-0 ml-3 text-white">{data.Patientcount}</h4>
                    <p className="mb-0 ml-3 extra-small-font text-white">Total Patients</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 col-xl-3">
            <div className="card gradient-quepal">
              <div className="card-body">
                <div className="media align-items-center" onClick={() => navigate(`/DoctorsMaster`)}>
                  <div className="w-icon"><i className="fa-solid fa-user-doctor text-white" /></div>
                  <div className="media-body ml-3 border-left-xs border-white-2">
                    <h4 className="mb-0 ml-3 text-white">{data.Doctorcount}</h4>
                    <p className="mb-0 ml-3 extra-small-font text-white">Total Doctors</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 col-xl-3">
            <div className="card gradient-scooter">
              <div className="card-body">
                <div className="media align-items-center" onClick={() => navigate(`/SupportMaster`)}>
                  <div className="w-icon"><i className="fa fa-comment-dots text-white" /></div>
                  <div className="media-body ml-3 border-left-xs border-white-2">
                    <h4 className="mb-0 ml-3 text-white">{data.Ticketscount}</h4>
                    <p className="mb-0 ml-3 extra-small-font text-white">Total Tickets</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4 col-xl-3">
            <div className="card gradient-blooker">
              <div className="card-body">
                <div className="media align-items-center" onClick={() => navigate(`/downloadrequest`)}>
                  <div className="w-icon"><i className="fa fa-download text-white" /></div>
                  <div className="media-body ml-3 border-left-xs border-white-2">
                    <h4 className="mb-0 ml-3 text-white">{data.DownloadReqcount}</h4>
                    <p className="mb-0 ml-3 extra-small-font text-white">Total Download Request</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div><h3>Appointment</h3></div>
        <div className="row mt-3">
          {data?.appointmentCountArray?.map((x, i) => (
            <div className="col-12 col-lg-4 col-xl-2">
              <div className="card gradient-scooter">
                <div className="card-body">
                  <div className="media align-items-center" onClick={() => navigate(`/appointment`, { state: x.status })}>
                    <div className="w-icon"><i className="fa-solid fa-calendar-check text-white" /></div>
                    <div className="media-body ml-3 border-left-xs border-white-2">
                      <h4 className="mb-0 ml-3 text-white">{x.count}</h4>
                      <p className="mb-0 ml-3 extra-small-font text-white">{x.status}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
  </>;
}
export default Manatee;