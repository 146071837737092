import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import DefaultLayout from '../layout/DefaultLayout';

import './App.css';



const App = () => {

 
  return (
    <div id="wrapper" style={{ height: "inherit" }}>
      <DefaultLayout />
    </div>
  );
}

export default App;
