import React, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Formik, Field, ErrorMessage } from "formik";
import {
    Post, Get, FileUpload, GetCountryList, GetStateList, GetCityList
} from '../../common/ajax';
import { Form, Button } from "react-bootstrap";
import { immediateToast } from 'izitoast-react';
import * as Yup from "yup";
import Select from 'react-select'
import { serialize } from 'object-to-formdata';
import { Dropdown } from 'react-bootstrap';
import { ConversationTypeStatus } from '../enum';
import moment from "moment";

const requiredGenderErrorMessage = "Gender Name field is required";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const addDataSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .matches(/^[A-Za-z]*$/, { message: "Please enter valid first name.", excludeEmptyString: false })
        .required("First name field is required"),
    middleName: Yup.string().matches(/^[A-Za-z]*$/, { message: "Please enter valid first name.", excludeEmptyString: false }),
    lastName: Yup.string().min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .matches(/^[A-Za-z]*$/, { message: "Please enter valid Last name.", excludeEmptyString: false })
        .required("Last name field is required"),
    gender: Yup.string().required(requiredGenderErrorMessage),
    email: Yup.string().email('Invalid email address').required("Email field is required"),
    mobileNo: Yup.string().min(10).max(10).matches(phoneRegExp, { message: "Please enter valid number.", excludeEmptyString: false }).required("Phone Number field is required"),
    address: Yup.string().required("Address field is required"),
    city: Yup.string().required("City field is required"),
    state: Yup.string().required("State field is required"),
    country: Yup.string().required("Country field is required"),
    specialization: Yup.array().required("specialization field is required")
});

const DoctorsAdd = () => {
    let params = useParams();
    const navigate = useNavigate();
    let role = JSON.parse(window.localStorage.getItem("AdminRole"));
    const [specializationList, setspecializationList] = useState([]);
    const [qualificationList, setqualificationList] = useState([]);
    const [specialization, setspecialization] = useState([]);
    const [qualification, setqualification] = useState([]);
    const [selectedDays, setselectedDays] = useState([]);
    const [imgUrl, setimgUrl] = useState("");
    const [imgName, setimgName] = useState("");
    const [selectedFile, setselectedFile] = useState(null);
    const [selectedDocFile, setselectedDocFile] = useState(null);
    const [docUrl, setdocUrl] = useState([]);
    const [newDocs, setnewDocs] = useState([]);
    const [countryList, setcountryList] = useState([]);
    const [stateList, setstateList] = useState([]);
    const [selectedState, setselectedState] = useState("");
    const [selectedPhoneCode, setselectedPhoneCode] = useState("");
    const [cityList, setcityList] = useState([]);
    const [selectedCity, setselectedCity] = useState("");
    const [expYear, setExpYear] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, "10+"]);
    const daysList = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const timingOption = ["0:00", "1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00", "8:00", "9:00", "10:00", "11:00", "12:00"]
    const amTiming = timingOption.map((item) => { return item + " AM"; });
    const pmTiming = timingOption.map((item) => {
        if (item != "0:00") {
            return item + " PM"
        }
        else { return "" }
    });
    const allTimes = [...amTiming, ...pmTiming.filter(item => item != 0.00)];
    const timeLimit = {
        From: "0:00 AM",
        To: "0:00 AM",
        apptType: []
    };
    const availability = daysList.map((dayVal) => { return { day: dayVal, time: [timeLimit], popShow: false } });
    const [initValue, setValue] = useState({
        id: "",
        firstName: "",
        middleName: "",
        lastName: "",
        gender: "",
        BirthDate: "",
        email: "",
        mobileNo: "",
        address: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        phoneCode: "",
        experience: "",
        about: "",
        specialization: [],
        qualification: [],
        image: "",
        availability: availability,
        file: null,
        is_active: true,
    });

    const clearData = () => {
        if (role == "Doctor") {
            getPost();
        }
        else {
            navigate('/DoctorsMaster');
        }
    }

    useEffect(() => {
        // alert(initValue.state);
        setselectedState(initValue.state);
        GetCitys(initValue.state);
    }, [stateList.length])

    useEffect(() => {
        setselectedCity(initValue.city);
    }, [cityList.length])

    useEffect(() => {

        fetch("https://ipapi.co/json/")
            .then(response => response.json())
            .then(data => {
                //var geo = geoip.lookup(data.ip);
                //console.log(data);
                setValue(item => ({ ...item, country: data.country_name, phoneCode: data.country_calling_code.replace("+", ""), state: data.region, city: data.city, stateName: data.state }));
                setselectedPhoneCode(data.country_calling_code.replace("+", ""));
                GetStates(data.country_name, false);
                if (params.id) {
                    getPost();
                }
            })
            .catch(error => {
                console.error("Error fetching IP address:", error);
            });

    }, [countryList])
    useEffect(() => {
        getSpecialization();
        getQualification();
        getCountry();
    }, []);

    useEffect(() => {
        if (params.id && specializationList.length > 0) {
            getPost();
        }
    }, [qualificationList.length > 0]);
    const getSpecialization = async () => {
        Get(`masters/getSpecialization/true`)
            .then((res) => {
                if (res.success) {
                    var item = res.data.map((item, i) => {
                        return { value: item._id, label: item.name }
                    })
                    setspecializationList(item);
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error getting in Spoecialization list" + err.message, "warning");
            });
    }
    const getQualification = async () => {
        Get(`masters/getQualification/true`)
            .then((res) => {
                if (res.success) {
                    var item = res.data.map((item, i) => {
                        return { value: item._id, label: item.name }
                    })
                    setqualificationList(item);
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error getting in Qualification list" + err.message, "warning");
            });
    }
    const getPost = async () => {
        Get(`doctors/getDoctorById/${params.id}`)
            .then((res) => {
                if (res.data) {
                    try {
                        if (!res.data.availability || res.data.availability.length <= 0) {
                            res.data.availability = availability;
                        }
                        var sp = specializationList.filter((item) => res.data.specialization.includes(item.value))
                        var qual = qualificationList.filter((item) => res.data.qualification.includes(item.value))

                        if (res.data.image) {
                            setimgName(res.data.image);
                            setimgUrl(process.env.REACT_APP_APIURL + "static/" + res.data.image);
                            setselectedFile(null);
                        }
                        setnewDocs(res.data.document);
                        setdocUrl(res.data.document);
                        setqualification(qual);
                        setspecialization(sp);
                        setresponcevalue(res.data);

                        setselectedState(res.data.state);
                        setselectedCity(res.data.city);
                        GetStates(res.data.country,true);
                    }
                    catch (err) {
                        showTost(err, "warning");
                    }
                }
            })
    }
    const getCountry = async () => {
        await GetCountryList().then((res) => { setcountryList(res.sort((a, b) => a.name > b.name ? 1 : -1)); })
    }
    const GetStates = async (value, load) => {
        var selectedCountry = countryList.filter(country => country.name === value);
        if (selectedCountry.length > 0) {
            setValue(item => ({ ...item, country: value, phonecode: selectedCountry[0].phonecode }));
            setselectedPhoneCode(selectedCountry[0].phonecode);
            await GetStateList(selectedCountry[0].iso2).then((res) => {
                var states = res.sort((a, b) => a.name > b.name ? 1 : -1);
                if (load) {
                    initValue.state = states[0].name;
                    setValue(item => ({ ...item, state: states[0].name }));
                }
                //setselectedState(initValue.state);
                setstateList(states);
            })
        }
    }

    const GetCitys = async (value) => {
        if (value) {
            setValue(item => ({ ...item, state: value }));
            setselectedState(value);
            var selectedCountry = countryList.filter(country => country.name === initValue.country);
            var selectedState = stateList.filter(state => state.name === value);
            if (selectedState.length > 0) {
                await GetCityList(selectedCountry[0].iso2, selectedState[0].iso2).then((res) => { setcityList(res.sort((a, b) => a.name > b.name ? 1 : -1)); })
            }
        }
    }

    const setresponcevalue = async (res) => {
        setValue({
            id: params.id,
            firstName: res.firstName,
            middleName: res.middleName,
            lastName: res.lastName,
            gender: res.gender,
            BirthDate: res.BirthDate ? moment(res.BirthDate).format("YYYY-MM-DD") : "",
            // BirthDate: moment(res.BirthDate).format("YYYY-MM-DD"),
            email: res.email,
            mobileNo: res.mobileNo,
            address: res.address,
            address2: res.address2,
            specialization: res.specialization,
            qualification: res.qualification,
            image: res.image,
            availability: res.availability,
            country: res.country,
            state: res.state,
            city: res.city,
            experience: res.experience,
            zipcode: res.zipcode,
            phoneCode: res.phoneCode,
            about: res.about
        });
    }

    const showTost = (msg, theme) => {
        theme = theme || "info";
        immediateToast(theme, {
            message: msg,
            timeout: 3000,
            position: "topRight"
        });
    }
    const removeFile = async (name, type) => {

        if (selectedFile && type === "img") {
            document.getElementById("uploadFile").files = null;
            setimgUrl(null);
            setimgName("");
            setselectedFile(null);
            return;
        }
        if (type === "doc") {
            var idx = -1;

            const dt = new DataTransfer();
            if (selectedDocFile) {

                for (var i = 0; i < selectedDocFile.length; i++) {
                    if (name !== selectedDocFile[i].name) {
                        dt.items.add(selectedDocFile[i]);
                    }
                    else {
                        idx = i;
                    }
                }
            }
            var nameIdx = docUrl.indexOf(name);
            var dURL = docUrl;
            dURL.splice(nameIdx, 1);
            setdocUrl(dURL);

            if (idx !== -1) {
                setselectedDocFile(dt.files);
                return;
            }

        }
        Post(`doctors/removeFile/`, { id: params.id, type: type, name: name })
            .then((res) => {
                if (res.success == true) {
                    showTost(res.message, "success");
                    if (type === "img") {
                        setimgUrl("");
                        setselectedFile(null);
                    }
                    else {
                        window.location.reload();
                    }
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error getting when doctor document remove." + err.message, "warning");
            });

    }
    const handleSubmit = async (value) => {
        let val = { ...value };
        if (specialization.length == 0) {
            showTost("Specialization field is required.");
            return false;
        } else {
            //checking if appointments type is not blank
            var appTypeBlank = false;
            await val.availability.map((tm) => {
                tm.time.map((t) => {
                    if (t.From != "0:00 AM" && t.To != "0:00 AM" && t.apptType.length == 0) {
                        appTypeBlank = true;
                    }
                })
            })
            if (appTypeBlank) {
                showTost("Please Select Appointment Type.");
                return;
            }
            if (selectedDocFile <= 0 && docUrl.length <= 0) {
                showTost("Please Upload Register Documents.");
                return;
            }
            val.file = selectedFile;
            val.specialization = specialization.map(spec => { return spec.value; });
            val.qualification = qualification.map(qual => { return qual.value; });
            val.availability = JSON.stringify(val.availability);
            val.availableDoc = newDocs;
            val.city = selectedCity;
            //val.document = selectedDocFile;
            const formData = serialize(val, { dotsForObjectNotation: true })
            if (selectedDocFile) {
                for (var i = 0; i < selectedDocFile.length; i++) {
                    formData.append("document[]", selectedDocFile[i]);
                }
            }
            FileUpload(`doctors/addDoctors`, formData)
                .then((res) => {
                    if (res.success == false) {
                        showTost(res.message);
                        return false;
                    }
                    else {
                        showTost(res.message);
                        if (role == "Doctor") {
                            getPost();
                        }
                        else {
                            navigate('/DoctorsMaster');
                        }
                    }
                })
                .catch((err) => {
                    showTost("Error getting when doctor save data." + err.message, "warning");
                });
        }

    };
    const handletextClick = () => {
        document.getElementById("uploadFile").click();
    }

    const handledocClick = () => {
        document.getElementById("uploadDoc").click();
    }

    const handledocChange = () => {
        var files = document.getElementById("uploadDoc").files;

        setdocUrl([]);
        if (files) {
            const dt = new DataTransfer();
            var newFile = [];
            if (selectedDocFile) {
                for (var i = 0; i < selectedDocFile.length; i++) {
                    dt.items.add(selectedDocFile[i]);
                    newFile.push(selectedDocFile[i].name)
                }
                setselectedDocFile(dt.files);
            }
            else {
                setselectedDocFile(files);
            }
            for (var i = 0; i < files.length; i++) {
                dt.items.add(files[i]);
                newFile.push(files[i].name)
            }

            var docs = [];
            for (var i = 0; i < dt.files.length; i++) {
                docs.push(dt.files[i].name);
            }
            for (var i = 0; i < docUrl.length; i++) {
                docs.push(docUrl[i]);
            }
            setdocUrl(docs);

        }
    }

    const handletextChange = () => {
        var file = document.getElementById("uploadFile").files[0];
        if (file) {
            var name = file.name;
            setimgName(name);
            setimgUrl(URL.createObjectURL(file));
            setselectedFile(file);
        }
    }

    const handleSpecChange = (option) => {
        setspecialization(option);

    }
    const handleQualChange = (option) => {
        setqualification(option);

    }
    const handlePopShow = async (dIdx, pShow) => {
        var newVal = JSON.parse(JSON.stringify(initValue));
        newVal.availability[dIdx].popShow = !pShow;
        setValue(initValue => ({ ...initValue, ...newVal }));

    }
    const copyTime = async (dIdx, values) => {
        const newVal = JSON.parse(JSON.stringify(values)); // Create a deep copy of the values
        const copyData = newVal.availability[dIdx].time; // Get the time data to be copied

        // Update the time for selected days
        const updatedAvailability = newVal.availability.map((av) => {
            if (selectedDays.includes(av.day)) {
                return { ...av, time: copyData }; // Update the time for the matching day
            }
            return av; // Return the original object if no match
        });

        initValue.availability = updatedAvailability;

    }
    const convertTime12to24 = (time12h) => {
        const [time, modifier] = time12h.split(' ');

        let [hours, minutes] = time.split(':');

        if (hours === '12') {
            hours = '00';
        }

        if (modifier === 'PM') {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}${minutes}`;
    }
    const setTimeValue = async (dIdx, tIdx, value, type) => {
        var newVal = JSON.parse(JSON.stringify(initValue));
        var timeExist = false;
        var timeGreater = false;
        var time24 = convertTime12to24(value);
        await newVal.availability.map((d, idx) => {
            d.time.map((t, index) => {
                if (index === tIdx && idx === dIdx) return;
                var from24 = convertTime12to24(t.From);
                var to24 = convertTime12to24(t.To);
                if (time24 <= from24 && time24 >= to24) {
                    timeGreater = true;
                }
                if (type == "From") {
                    if (t.From == value) {
                        timeExist = true;
                    }
                    if (value >= t.From && value < t.To) {
                        timeGreater = true;
                    }
                }
                else {
                    if (t.To == value) {
                        timeExist = true;
                    }
                    // if (t.From < value && value <= t.To) {
                    //     timeGreater = true;
                    // }
                }

            });
        })
        if (timeExist) {
            showTost("Time slot already exists");
            return false;
        }
        if (timeGreater) {
            showTost("Invalid Time Selection");
            return false;
        }
        if (type === "To" && convertTime12to24(newVal.availability[dIdx].time[tIdx].From) >= time24) {
            showTost("To time must be greater than From time");
            return false;
        }
        if (type == "From") {
            newVal.availability[dIdx].time[tIdx].From = value;
        }
        else {
            newVal.availability[dIdx].time[tIdx].To = value;
        }
        setValue(initValue => ({ ...initValue, ...newVal }));

    }
    const addTime = async (dIdx, value) => {
        var newVal = JSON.parse(JSON.stringify(value));
        newVal.availability[dIdx].time.push(timeLimit);
        setValue(initValue => ({ ...initValue, ...newVal }));
    }

    const removeTime = async (dIdx, tIdx, newVal) => {
        newVal.availability[dIdx].time.splice(tIdx, 1);
        setValue(initValue => ({ ...initValue, ...newVal }));
    }

    const toggleSelectedDays = async (option) => {
        var item = selectedDays;
        if (selectedDays.includes(option)) {
            item = item.filter(x => x !== option);
        } else {
            item.push(option);
        }
        setselectedDays([...selectedDays, ...item]);
    }

    useEffect(() => { setValue(initValue) }, [initValue])
    // Custom styles for react-select
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            color: state.data.value.includes("AM") ? "#1d94f0" : "#eb265a", // Conditionally change color
            backgroundColor: state.isSelected ? "#f0f0f0" : state.isFocused ? "#e0e0e0" : "white",
        }),
        control: (provided) => ({
            ...provided,
            borderColor: "gray",
            "&:hover": { borderColor: "black" }
        }),
        // Style for the selected single value
        singleValue: (provided, state) => ({
            ...provided,
            color: state.data.value.includes("AM") ? "#1d94f0" : "#eb265a", // Conditionally change color based on value
        })
    };
    return (
        <div className="container-fluid">
            <Formik
                enableReinitialize={true}
                onSubmit={handleSubmit}
                initialValues={initValue}
                validationSchema={addDataSchema}
            >
                {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                    setValues
                }) => {
                    return (
                        <>
                            <div className="row pt-2 pb-2">
                                <div className="col-sm-9">
                                    <h4 className="page-title">{params.id ? "Edit Doctors" : "Add Doctors"}</h4>
                                </div>
                                {role == "Doctor" ? "" :
                                    <div className="col-sm-3">
                                        <div className="btn-group float-sm-right">
                                            <Link to={"/DoctorsMaster"}><button type="button" className="btn btn-light waves-effect waves-light"><i className="fa fa-bars mr-1" /> View Doctors</button></Link>
                                        </div>
                                    </div>}
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <Form id="textmasterform"
                                                onSubmit={handleSubmit}
                                                onChange={handleChange}>
                                                <div className="row">
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} /> First Name:</label>
                                                            <Field value={values.firstName} type="text" name="firstName" className={`form-control ${touched.firstName && errors.firstName ? "is-invalid" : ""}`} />
                                                            <ErrorMessage name="firstName" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"> Middle Name :</label>
                                                            <Field value={values.middleName} type="text" name="middleName" className={`form-control ${touched.middleName && errors.middleName ? "is-invalid" : ""}`} />
                                                            <ErrorMessage name="middleName" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} /> Last Name:</label>
                                                            <Field value={values.lastName} type="text" name="lastName" className={`form-control ${touched.lastName && errors.lastName ? "is-invalid" : ""}`} />
                                                            <ErrorMessage name="lastName" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} /> Gender:</label>
                                                            <Form.Control
                                                                className="input_focus"
                                                                as="select"
                                                                name="gender"
                                                                value={values.gender}
                                                                isInvalid={!!touched.gender && !!errors.gender}
                                                            >
                                                                <option value="">Select</option>
                                                                <option value="male">Male</option>
                                                                <option value="female">Female</option>
                                                                <option value="I do not want to specify">I do not want to specify</option>
                                                            </Form.Control>
                                                            <ErrorMessage name="gender" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className='col-12 col-lg-4 col-xl-4'>
                                                        <label className="col-form-label">Birth Date:</label>
                                                        <Field value={values.BirthDate} type="date" name="BirthDate" className={`form-control ${touched.BirthDate && errors.BirthDate ? "is-invalid" : ""}`} />
                                                        <ErrorMessage name="BirthDate" component="span" className="error" />
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} /> Email:</label>
                                                            <Field value={values.email} type="email" name="email" className={`form-control ${touched.email && errors.email ? "is-invalid" : ""}`} />
                                                            <ErrorMessage name="email" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} /> Specialization:</label>
                                                            <Select
                                                                options={specializationList}
                                                                isMulti
                                                                name="specialization"
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                value={specialization}
                                                                onChange={handleSpecChange}
                                                            />
                                                            <ErrorMessage name="specialization" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label">Qualification:</label>
                                                            <Select
                                                                options={qualificationList}
                                                                isMulti
                                                                name="qualification"
                                                                className="basic-multi-select"
                                                                classNamePrefix="select"
                                                                value={qualification}
                                                                onChange={handleQualChange}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label">Experience:</label>
                                                            <select className='form-control' value={values.experience} onChange={(e) => { values.experience = e.target.value }}>
                                                                {expYear && expYear.map((exp, i) => {
                                                                    return (
                                                                        <>
                                                                            <option key={Math.random()} value={exp}>{exp} Year</option>
                                                                        </>
                                                                    )
                                                                })}
                                                            </select>
                                                            <ErrorMessage name="experience" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} /> Mobile:</label>
                                                            <div className='row'>
                                                                <div className='col-4'>
                                                                    <select className='form-control' value={selectedPhoneCode} onChange={(e) => { setselectedPhoneCode(e.target.value); setValue(item => ({ ...item, phoneCode: e.target.value })); }}>
                                                                        {countryList && countryList.map((country, i) => {
                                                                            return (
                                                                                <>
                                                                                    <option key={Math.random()} value={country.phonecode}>{`${country.phonecode} ( ${country.iso2} )`} </option>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                <div className='col-8'>
                                                                    <Field value={values.mobileNo} type="text" name="mobileNo" className={`form-control ${touched.mobileNo && errors.mobileNo ? "is-invalid" : ""}`} />
                                                                </div>
                                                            </div>
                                                            <ErrorMessage name="mobileNo" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} /> Address1:</label>
                                                            <Field value={values.address} type="text" name="address" className={`form-control ${touched.address && errors.address ? "is-invalid" : ""}`} />
                                                            <ErrorMessage name="address" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label">Address2:</label>
                                                            <Field value={values.address2} type="text" name="address2" className={`form-control`} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} />Country:</label>
                                                            {/* <Field value={values.country} type="text" name="country" className={`form-control`} /> */}
                                                            <select className='form-control' value={values.country} onChange={(e) => { GetStates(e.target.value,true) }} name="country">
                                                                {countryList && countryList.map((country, i) => {
                                                                    return (
                                                                        <>
                                                                            <option key={Math.random()} value={country.name}>{country.name} </option>
                                                                        </>
                                                                    )
                                                                })}
                                                            </select>
                                                            <ErrorMessage name="country" component="span" className="error" />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} />State:</label>
                                                            {/* <Field value={values.state} type="text" name="state" className={`form-control`} /> */}
                                                            <select className='form-control' value={selectedState} onChange={(e) => { GetCitys(e.target.value) }} name="state">
                                                                {stateList && stateList.map((state, i) => {
                                                                    return (
                                                                        <>
                                                                            <option key={Math.random()} value={state.name}>{state.name} </option>
                                                                        </>
                                                                    )
                                                                })}
                                                            </select>
                                                            <ErrorMessage name="state" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label"><i className="fa fa-asterisk" style={{ color: 'red', fontSize: '11px' }} />City:</label>
                                                            {/* <Field value={values.city} type="text" name="city" className={`form-control`} /> */}
                                                            <select className='form-control' value={selectedCity} onChange={(e) => { setselectedCity(e.target.value); }} name="city">
                                                                {cityList && cityList.map((city, i) => {
                                                                    return (
                                                                        <>
                                                                            <option key={Math.random()} value={city.name}>{city.name} </option>
                                                                        </>
                                                                    )
                                                                })}
                                                            </select>
                                                            <ErrorMessage name="city" component="span" className="error" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label">ZipCode:</label>
                                                            <Field value={values.zipcode} type="text" name="zipcode" className={`form-control`} />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-lg-4 col-xl-4">
                                                        <div className="form-group">
                                                            <label className="col-form-label">About:</label>
                                                            <Field value={values.about} type="textarea" name="about" className={`form-control`} />
                                                            <ErrorMessage name="about" component="span" className="error" />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-lg-6 col-xl-6 row">
                                                        <input id="uploadFile" type="file" name="file" accept="application/.jpg,.jpeg,.png,.gif" onChange={handletextChange} style={{ "display": "none" }} />
                                                        <div className="uploadBlock mb-4 mt-3">
                                                            <a href="#" className="uploadLink" value="Upload File" onClick={handletextClick} style={{ marginTop: "-20px" }} >
                                                                <em className="fa fa-arrow-up" ></em><br></br>Upload Profile Pic
                                                            </a>
                                                        </div>
                                                        <div className="col-12 col-lg-6 col-xl-6">
                                                            {imgUrl && <div>
                                                                <img src={imgUrl} style={{ height: "100px", width: "100px", border: "1px solid #d3d3d3" }} />
                                                                {selectedFile != null || imgUrl != null ?
                                                                    <Button title="Remove" className="btn btn-danger waves-effect waves-light removeButton btn-sm" onClick={() => removeFile(imgName, "img")}>X</Button>
                                                                    : ""}
                                                            </div>}
                                                            {/* <label className="uploadFileName" name="fileName" >{textUrl}</label> */}

                                                        </div>
                                                    </div>
                                                    <div className="col-12 row">
                                                        <input id="uploadDoc" type="file" name="document" accept="application/.pdf,.doc,.docx" onChange={handledocChange} style={{ "display": "none" }} multiple />
                                                        <div className="uploadBlock mb-4 mt-3">
                                                            <a href="#" className="uploadLink" value="Upload File" onClick={handledocClick} style={{ marginTop: "-20px" }} >
                                                                <em className="fa fa-arrow-up" ></em><br></br>Upload Document
                                                            </a>
                                                        </div>
                                                        <div className="ml-3 row col-6" style={{ marginTop: "20px" }}>
                                                            {docUrl && docUrl.map((url, i) => {
                                                                return (
                                                                    <>
                                                                        <div key={Math.random()} style={{ display: "block" }} >
                                                                            <a target='_blank' href={process.env.REACT_APP_APIURL + "static/" + url}>{url}</a>
                                                                            <Button style={{ marginTop: "0px", marginBottom: "1px" }} title="Remove" className="btn btn-danger ml-2 waves-effect waves-light removeButton btn-sm" onClick={() => removeFile(url, "doc")}>X</Button>
                                                                        </div><br />
                                                                    </>
                                                                )
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div className="col-12" style={{ marginTop: "10px" }}>
                                                        <label className="col-form-label">Availibility: </label>
                                                        {values.availability && values.availability.map((dayItem, d) => {
                                                            return (
                                                                <div className='col-12 row' key={Math.random()}>

                                                                    <div className="col-12 col-lg-2 col-xl-2"><label style={{ fontSize: "14px", fontWeight: "normal" }} > {dayItem.day}</label></div>
                                                                    <div className="col-12 col-lg-8 col-xl-8 " >
                                                                        {dayItem.time && dayItem.time.map((item, i) => {
                                                                            return (
                                                                                <div className='row' key={Math.random()}>
                                                                                    <div className="col-5 col-lg-3 col-xl-3 form-group">
                                                                                        <Select
                                                                                            options={allTimes.map((item, i) => { return { value: item, label: item } })}
                                                                                            name="TimeFrom"
                                                                                            placeholder={'select'}
                                                                                            className="basic-multi-select"
                                                                                            classNamePrefix="select"
                                                                                            defaultValue={{ label: item.From, value: item.From }}
                                                                                            onChange={(e) => { setTimeValue(d, i, e.value, "From"); setValues(initValue); }}
                                                                                            styles={customStyles}
                                                                                        />
                                                                                    </div>
                                                                                    <span className='form-control-xl col-1 text-center col-lg-1 col-xl-1'>-</span>
                                                                                    <div className="col-5 col-lg-3 col-xl-3 form-group">
                                                                                        <Select
                                                                                            options={allTimes.map((item, i) => { return { value: item, label: item } })}
                                                                                            name="TimeTo"
                                                                                            className="basic-multi-select"
                                                                                            classNamePrefix="select"
                                                                                            defaultValue={{ label: item.To, value: item.To }}
                                                                                            onChange={(e) => { setTimeValue(d, i, e.value, "To"); setValues(initValue); }}
                                                                                            styles={customStyles}
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-12 col-lg-4 col-xl-4 mb-2">
                                                                                        <Select
                                                                                            options={ConversationTypeStatus.map(appt => { return { label: appt, value: appt } })}
                                                                                            isMulti
                                                                                            name="qualification"
                                                                                            className="basic-multi-select"
                                                                                            classNamePrefix="select"
                                                                                            defaultValue={item.apptType.map(appt => { return { label: appt, value: appt } })}
                                                                                            onChange={(option) => { item.apptType = option.map((item) => { return item.value; }) }}
                                                                                        />
                                                                                    </div>
                                                                                    <div style={{ position: "absolute", right: "0" }}>
                                                                                        <em className='fa fa-trash btn btn-sm btn-link iconButton' style={{ height: "30px" }} title='Remove Row' onClick={(e) => { removeTime(d, i, values); setValues(initValue) }}></em>
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        }
                                                                        {dayItem.time.length <= 0 && <>
                                                                            <label>Unavailable</label>
                                                                        </>}

                                                                    </div>
                                                                    <div className='availibilityBlock col-2 row'>
                                                                        <em className='col-2 fa fa-plus btn btn-sm btn-link iconButton' title='Add Row' onClick={(e) => { addTime(d, values); setValues(initValue); }}></em>
                                                                        <Dropdown className="col-2" autoClose="outside" show={dayItem.popShow} onToggle={async () => { handlePopShow(d, dayItem.popShow); setValues(initValue); setselectedDays([]); }}>
                                                                            <Dropdown.Toggle
                                                                                id="dropdown-basic"
                                                                                variant=''
                                                                                onClick={async () => { handlePopShow(d, dayItem.popShow); setValues(initValue); setselectedDays([]); }}>
                                                                                <em className='fa fa-copy btn btn-sm btn-link iconButton' ></em>
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                {daysList.map((option, index) => (
                                                                                    <Dropdown.Item
                                                                                        key={index}
                                                                                        onClick={() => { toggleSelectedDays(option); }}
                                                                                        active={
                                                                                            selectedDays.includes(option)}
                                                                                    >
                                                                                        {option}
                                                                                    </Dropdown.Item>
                                                                                ))}
                                                                                <div className='popupFooter'>
                                                                                    <input type='button' className='btn btn-sm btn-success' value='Ok' style={{ marginLeft: "10px" }} onClick={async (e) => { copyTime(d, values); handlePopShow(d, dayItem.popShow); setValues(initValue); }} />
                                                                                    <input type='button' className='btn btn-sm btn-danger' value='Close' style={{ marginLeft: "10px" }} onClick={() => { handlePopShow(d, dayItem.popShow); setValues(initValue); }} />
                                                                                </div>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="col-12">
                                                        <Button type="reset" className="btn btn-danger waves-effect waves-light float-right ml-2" onClick={() => clearData()}>Cancel</Button>
                                                        <Button type="submit" className={(params.id ? "btn-success" : "btn-light") + " btn waves-effect waves-light float-right"}>{params.id ? "Update" : "Add New"}</Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    );
                }}
            </Formik>
        </div>
    )
}
export default DoctorsAdd;