import React, { useEffect, StrictMode, useState, useRef } from 'react';
import { Post } from '../../common/ajax';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { useParams } from 'react-router-dom';
import { VideoRoom } from './VideoRoom';

const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })
const Meeting = () => {
    const ref = useRef()
    const { id } = useParams();
    const [appId, setAppId] = useState("");
    const [token, settoken] = useState("");
    const [channel, setChannel] = useState("");
    const [Doctor, setDoctor] = useState("");
    const [Patient, setPatient] = useState("");
    const [mUsers, setmUsers] = useState([]);
    let channelName = 'test';

    useEffect(() => {
        getMeetingDetail()
    }, [])

    const getMeetingDetail = async () => {
        await Post(`appointment/AudioVideoMeeting`, { channelName: channelName, apptId: id })
            .then((res) => {
                if (res.success) {
                    var data = res.data;
                    //console.log(data, "data");
                    //createMeetings(data.token, data.APP_ID, channelName);
                    var docDetail = data.detail.doctorId;
                    var patDetail = data.detail.patientId;
                    // if (data.detail.conversationType === "Video") {
                    //     setisVideo(true);
                    // }
                    setDoctor("Dr. " + docDetail.firstName + " " + docDetail.lastName);
                    setPatient(patDetail.firstName + " " + patDetail.lastName);
                    setAppId(data.APP_ID);
                    setChannel(channelName);
                    settoken(data.token);

                } else {
                    alert(res.message);
                }
            })
            .catch((err) => {
                alert("Error in generating meetinglink." + err,);
            });

    }
    useEffect(() => { startMeetings(); }, [token]);
    useEffect(() => { 
        console.log(mUsers, "users")
        // client.publish(mUsers[0].audioTrack, mUsers[0].videoTrack);
        // mUsers[0].videoTrack.play(ref.current);
    }, [mUsers]);
    const startMeetings = async () => {
        if (!token) return;
        var newUser ={};
        await client.join(appId, channel, token, null)
            .then((uid) => Promise.all([AgoraRTC.createMicrophoneAndCameraTracks(), uid]))
            .then(([tracks, uid]) => {
                const [audioTrack, videoTrack] = tracks;
                newUser = { uid: uid, videoTrack: videoTrack, audioTrack: audioTrack };
                setmUsers((previousUsers) => [...previousUsers, newUser])

                setTimeout(() => {
                    console.log(mUsers, "usr1");

                    client.publish(audioTrack, videoTrack);
                    videoTrack.play(ref.current);
                }, 1000);

            })


        setTimeout(() => {
            console.log(mUsers, "usr2");
        }, 1000);
        client.on('user-published', handleUserJoined);
        client.on('user-left', handleUserLeft);
    }

    const handleUserJoined = async (user, mediaType) => {
        await client.subscribe(user, mediaType);

        if (mediaType === 'video') {
            setmUsers((previousUsers) => [...previousUsers, user])
        }

        if (mediaType === 'audio') {
            user.audioTrack.play();
        }
    }
    const handleUserLeft = async (user) => {
        setmUsers((previousUsers) =>
            previousUsers.filter((u) => u.uid !== user.uid)
        )

    }
    return (
        <>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <label>New Meeting</label>
                {mUsers.map((user) => {
                    <div key={user.uid}>{user.uid}
                        <div style={{ display: "grid", gridTemplateColumns: "repeat(2,200px)" }}>
                            <div ref={ref} style={{ width: "200px", height: "200px" }} ></div>
                        </div>
                    </div>
                })}

            </div>
        </>
    )



//const Meeting = () => {
    return (
        <>
            <VideoRoom />
        </>
    )
}
export default Meeting;