import React, { Component } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import PatientsMaster from '../pages/Patients/Patients';
import PatientsAdd from '../pages/Patients/PatientsAdd';
import DoctorMaster from '../pages/Doctors/Doctors';
import DoctorAdd from '../pages/Doctors/DoctorsAdd';
import DoctorPayment from '../pages/Doctors/DoctorPayment';
import Dashboard from '../pages/dashboard';
import DoctorDashboard from '../pages/DoctorDashboard';
import CMSMaster from '../pages/CMS/CMS';
import CMSAdd from '../pages/CMS/CMSAdd';
import SupportMaster from '../pages/Support/Support';
import SupportAdd from '../pages/Support/SupportAdd';
import DownloadRequest from '../pages/Downloadrequest/DownloadRequest';
import Appointment from '../pages/Appointment/Appointment';
import Chat from '../pages/Appointment/Chat';
import AppointmentAdd from '../pages/Appointment/AppointmentAdd';
import SpecializationMaster from '../pages/Master/Specialization';
import SpecializationAdd from '../pages/Master/SpecializationAdd';
import QualificationMaster from '../pages/Master/Qualification';
import QualificationAdd from '../pages/Master/QualificationAdd';
import Forgotpassword from '../pages/forgot-password';
import Login from '../pages/login';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';
import Meeting from '../pages/Appointment/Metting';
import Meeting2 from '../pages/Appointment/Meeting2';
import ProtectedRoute from './ProtectedRoute';

const DefaultLayout = () => {
    const $ = window.$;
    const islogin = JSON.parse(window.localStorage.getItem("AdminToken"));
    const up = async () => {
        $('.back-to-top').on("click", function () {
            $("html, body").animate({ scrollTop: 0 }, 600);
            return false;
        });
    }
    let role = JSON.parse(window.localStorage.getItem("AdminRole"));
 
    return <div style={{ height: "inherit" }}>
        
        {islogin && <>
            <div style={{ height: "inherit" }}>
                <BrowserRouter>
                    <Sidebar />
                    <Header />
                    <div className="content-wrapper">
                        <ProtectedRoute>
                            <Routes>

                                {role == "Doctor" ? <>
                                    <Route path="/" exact element={<DoctorDashboard />} />
                                    <Route path="/dashboard" exact element={<DoctorDashboard />} />
                                    <Route path="/appointment" exact element={<Appointment />} />
                                    <Route path="/chat/:id" exact element={<Chat />} />
                                    <Route path="/DoctorsAdd/:id" exact element={<DoctorAdd />} />
                                    <Route path="/SupportMaster" exact element={<SupportMaster />} />
                                    <Route path="/SupportAdd" exact element={<SupportAdd />} />
                                </> : <>
                                    <Route path="/" exact element={<Dashboard />} />
                                    <Route path="/dashboard" exact element={<Dashboard />} />

                                    <Route path="/CMSMaster" exact element={<CMSMaster />} />
                                    <Route path="/CMSAdd">
                                        <Route path=":id" element={<CMSAdd />} />
                                        <Route path="" element={<CMSAdd />} />
                                    </Route>
                                    <Route path="/SupportMaster" exact element={<SupportMaster />} />
                                    <Route path="/SupportAdd">
                                        <Route path=":id" element={<SupportAdd />} />
                                        <Route path="" element={<SupportAdd />} />
                                    </Route>
                                    <Route path="/SpecializationMaster" exact element={<SpecializationMaster />} />
                                    <Route path="/SpecializationAdd">
                                        <Route path=":id" element={<SpecializationAdd />} />
                                        <Route path="" element={<SpecializationAdd />} />
                                    </Route>
                                    <Route path="/QualificationMaster" exact element={<QualificationMaster />} />
                                    <Route path="/QualificationAdd">
                                        <Route path=":id" element={<QualificationAdd />} />
                                        <Route path="" element={<QualificationAdd />} />
                                    </Route>
                                    <Route path="/PatientsMaster" exact element={<PatientsMaster />} />
                                    <Route path="/PatientsAdd">
                                        <Route path=":id" element={<PatientsAdd />} />
                                        <Route path="" element={<PatientsAdd />} />
                                    </Route>
                                    <Route path="/DoctorsMaster" exact element={<DoctorMaster />} />
                                    <Route path="/DoctorsAdd">
                                        <Route path=":id" element={<DoctorAdd />} />
                                        <Route path="" element={<DoctorAdd />} />
                                    </Route>
                                    <Route path="/DoctorPayment/:doctorid/:id" element={<DoctorPayment />} />
                                    <Route path="/downloadrequest" exact element={<DownloadRequest />} />
                                    <Route path="/appointment" exact element={<Appointment />} />
                                    <Route path="/AppointmentAdd">
                                        <Route path=":id" element={<AppointmentAdd />} />
                                        <Route path="" element={<AppointmentAdd />} />
                                    </Route>
                                </>}
                                <Route path="/Meeting/:id" element={<Meeting />} />
                                <Route path="/Meeting2/:id" element={<Meeting2 />} />
                                <Route path="" element={<Navigate replace to="/index" />} />
                            </Routes>
                        </ProtectedRoute>
                    </div>
                    <a href="#" onClick={() => up()} className="back-to-top"><i className="fa fa-angle-double-up"></i> </a>
                    <Footer />
                </BrowserRouter>
            </div>
        </>}
        {!islogin && <>
            <div>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" exact element={<Login />} />
                        <Route path="/index" exact element={<Login />} />
                        <Route path="/forgot-password" exact element={<Forgotpassword />} />

                    </Routes>
                </BrowserRouter>
            </div>
        </>}
    </div>;
}

export default DefaultLayout;