import {
    LocalUser,
    RemoteUser,
    useIsConnected,
    useJoin,
    useLocalMicrophoneTrack,
    useLocalCameraTrack,
    usePublish,
    useRemoteUsers,
    useCurrentUID
} from "agora-rtc-react";
import React, { useState, useEffect } from "react";
import { Post } from '../../common/ajax';
import "./meetingstyles.css";
import { useParams } from 'react-router-dom';

export const Agora = (props) => {
    let channelName = 'test';
    const { id } = useParams();
    //const Auid = useCurrentUID();
    const uid = JSON.parse(localStorage.getItem("Email"));
    const userRole = JSON.parse(localStorage.getItem("AdminRole"));
    const meetingUser = JSON.parse(localStorage.getItem("AdminUsername"));
    let calling = props.calling;
    const isConnected = useIsConnected();
    const [appId, setAppId] = useState("");
    const [token, setApptoken] = useState("");
    const [channel, setChannel] = useState("");
    const [Doctor, setDoctor] = useState("");
    const [Patient, setPatient] = useState("");
    const Auid =useJoin({ appid: appId, channel: channel, token: token ? token : null, meetingUser }, calling);
    
    //local user
    const [micOn, setMic] = useState(true);
    const [cameraOn, setCamera] = useState(true);
    const [isVideo, setisVideo] = useState(false);
    const { localMicrophoneTrack } = useLocalMicrophoneTrack(true, { ANS: true, AEC: true });
    const { localCameraTrack } = useLocalCameraTrack(cameraOn);
    //const audioTrack = useLocalMicrophoneTrack(true, { ANS: true, AEC: true });
    //usePublish([localMicrophoneTrack, localCameraTrack]);
    //remote users
    const remoteUsers = useRemoteUsers();

    const startMeetings = async () => {
        await Post(`appointment/AudioVideoMeeting`, { channelName: channelName, apptId: id })
            .then((res) => {
                if (res.success) {
                    var data = res.data;
                    //console.log(data, "data");
                    //createMeetings(data.token, data.APP_ID, channelName);
                    var docDetail = data.detail.doctorId;
                    var patDetail = data.detail.patientId;
                    if (data.detail.conversationType === "Video") {
                        setisVideo(true);
                    }
                    setDoctor("Dr. " + docDetail.firstName + " " + docDetail.lastName);
                    setPatient(patDetail.firstName + " " + patDetail.lastName);
                    setAppId(data.APP_ID);
                    setChannel(channelName);
                    setApptoken(data.token);
                    props.setCalling(true);


                } else {
                    alert(res.message);
                }
            })
            .catch((err) => {
                alert("Error in generating meetinglink." + err,);
            });

    }
    useEffect(() => {
        if (!calling) {
            startMeetings();
        }
    }, []);
    return (
        <>
            <div className={`room ${props.calling ? "" : "loggingRoom"}`}>
                {isConnected ? (
                    <div className="user-list">
                        <div className={`user ${props.calling ? "thumbnailUser" : "col-10"}`}>
                            <LocalUser
                                audioTrack={localMicrophoneTrack}
                                cameraOn={cameraOn}
                                micOn={micOn}
                                videoTrack={localCameraTrack}
                                cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
                            >
                                <samp className="user-name">{userRole === "Doctor" ? Doctor : Patient}</samp>
                            </LocalUser>
                        </div>
                        {remoteUsers.map((user) => (
                            <div className="user" key={user.uid} style={{ width: "80%" }}>
                                <RemoteUser cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg" user={user}>
                                    <samp className="user-name">{userRole === "Doctor" ? Doctor : Patient}</samp>
                                </RemoteUser>
                            </div>
                        ))}
                    </div>
                ) : <div>
                    <label className="loadingLabel">Loading<label className="loader"></label></label>
                </div>}

                {isConnected && (
                    <div className="control">
                        <div className="left-control">
                            <button className="btnMeeting" onClick={() => setMic(a => !a)}>
                                <i className={`i-microphone ${!micOn ? "off" : ""}`} />
                            </button>
                            {isVideo &&
                                <button className="btnMeeting" onClick={() => setCamera(a => !a)}>
                                    <i className={`i-camera ${!cameraOn ? "off" : ""}`} />
                                </button>
                            }
                        </div>
                        <button
                            className={`btnMeeting btn-phone ${calling ? "btn-phone-active" : ""}`}
                            onClick={() => props.setCalling(a => !a)}
                        >
                            {calling ? <i className="i-phone-hangup" /> : <i className="i-mdi-phone" />}
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default Agora;
