import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Post, Get } from '../../common/ajax';
import { immediateToast } from 'izitoast-react';
import Dropdown from 'react-bootstrap/Dropdown';
import DialogBox from "../../common/DialogBox";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';

const Patients = () => {
    const [data, setData] = useState([]);
    const [showDialogBox, setDialogBox] = useState(false);
    const [showunlockDialogBox, setunlockDialogBox] = useState(false);
    const [dialogMessage, setdialogMessage] = useState("");
    const [rowId, setRowId] = useState("");
    const [rowStatus, setrowStatus] = useState(false);
    const [selectedDate, setSelectedDate] = useState("");
    const [Searchemail, setSearchemail] = useState("");
    const [Searchname, setSearchname] = useState("");
    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        appointment: { value: null, matchMode: FilterMatchMode.CONTAINS },
        email: { value: null, matchMode: FilterMatchMode.CONTAINS },
        mobileNo: { value: null, matchMode: FilterMatchMode.CONTAINS },
        is_active: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });

    useEffect(() => {
        getPost(true);
    }, [Searchemail, selectedDate, Searchname]);
    const showTost = (msg, theme) => {
        theme = theme || "info";
        immediateToast(theme, {
            message: msg,
            timeout: 3000,
            position: "topRight"
        });
    }


    const getPost = async (val) => {
        Post(`patients/getPatients`, {active : val, email: Searchemail, date : selectedDate , name : Searchname})
            .then(async(res) => {
                if (res.success) {
                    await res.data.map((row, i) => {
                        row.name = row.firstName + " " + row.middleName + " " + row.lastName;
                        row.is_active= row.is_active?"Active":"InActive";

                    })
                    setData(res.data);
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error getting in patient list" + err.message , "warning");
            });
    }
    const showStatusDiaglog = (rowId, status) => {
        setRowId(rowId);
        setrowStatus(!status);
        var val = status === true ? "inactivate" : "activate";
        setdialogMessage("Do you want to " + val + " Patient?");
        setDialogBox(true);
    }
    const showunlockDiaglog = (rowId) => {
        setRowId(rowId);
        setdialogMessage("Do you want to Unlock Patient?");
        setunlockDialogBox(true);
    }
    const changeStatus = async (id, active) => {
        Post(`patients/changePatientStatus`, { id: rowId, active: rowStatus })
            .then((res) => {
                if (res.success) {
                    showTost(res.message, "success");
                    // getPost(rowStatus);
                    setData((prevData) => prevData.filter((row) => row._id !== rowId));
                    setDialogBox(false);
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error getting when satus update of patient" + err.message , "warning");
            });
    }
    const unlockprofile = async () => {
        Get(`patients/unlockprofile/${rowId}`)
            .then((res) => {
                if (res.success) {
                    getPost(true);
                    setunlockDialogBox(false);
                }
                else {
                    showTost(res.message, "warning");
                }
            })
            .catch((err) => {
                showTost("Error getting when patient profile unlock" + err.message , "warning");
            });
    }

    const renderHeader = () => {
        return (
            <div className="flex justify-content-end">
                <IconField iconPosition="left">
                    <InputIcon className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                </IconField>
            </div>
        );
    };
    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const header = renderHeader();
    const verifiedBodyTemplate = (m) => {
        return (
            <Dropdown>
                <Dropdown.Toggle variant="success" size="sm" drop="end" id="dropdown-basic">
                    <i className='fa fa-cog'></i>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href={`/PatientsAdd/${m._id}`}>Edit</Dropdown.Item>
                    {m.is_lock == true ? <Dropdown.Item href='#' onClick={(e) => { showunlockDiaglog(m._id) }} >Unlock</Dropdown.Item> : ""}
                    {/* <Dropdown.Item href='#' onClick={(e) => { showStatusDiaglog(m._id, m.is_active === "Active" || m.is_active === true) }} >Set As {m.is_active === "Active" || m.is_active === true ? "InActive" : "Active"}</Dropdown.Item> */}
                    <Dropdown.Item href='#' onClick={(e) => { showStatusDiaglog(m._id, m.is_active === "Active") }} >Set As {m.is_active === "Active" ? "InActive" : "Active"}</Dropdown.Item>                
                </Dropdown.Menu>
            </Dropdown>
        )
    }
    return (
        <div className="container-fluid">
            <div className="row py-2 align-items-center">
                <div className="col-lg-2 col-12">
                    <h4 className="page-title">Patients Master</h4>
                </div>
                <div className="col-lg-10 col-12">
                    <div className='d-flex align-items-center flex-wrap justify-content-lg-end'>
                        <div className="btn-group mb-2 mb-lg-0 mr-2">
                            <select className='form-control' onChange={(e) => { getPost(e.target.value); setrowStatus(e.target.value) }}>
                                <option value="true">Active</option>
                                <option value="false">InActive</option>
                            </select>
                        </div>
                        <div className='mb-2 mb-lg-0 mr-2'>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search by Email"
                                value={Searchemail}
                                onChange={(e) => setSearchemail(e.target.value)}
                            />
                        </div>
                        <div className='mb-2 mb-lg-0 mr-2'>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Search by Name"
                                value={Searchname}
                                onChange={(e) => setSearchname(e.target.value)}
                            />
                        </div>
                        <div className='mb-2 mb-lg-0 mr-2'>
                            <input
                                type="date"
                                className="form-control"
                                placeholder="Select Date"
                                value={selectedDate}
                                onChange={(e) => setSelectedDate(e.target.value)}
                            />
                        </div>
                        <div className='mb-2 mb-lg-0'>
                            <div className="btn-group">
                                <Link to={"/PatientsAdd"}><button type="button" className="btn btn-light waves-effect waves-light"><i className="fa fa-plus mr-1" /> Add Patient</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable value={data} paginator rows={10} dataKey="_id" filters={filters} filterDisplay="row"
                                    globalFilterFields={['appointment', 'doctor', 'patient', 'conversationType', 'paymentAmount', 'status', 'reason']} header={header} emptyMessage="No Appointment found.">
                                    <Column field="" header="" style={{ minWidth: '6rem' }} body={verifiedBodyTemplate} />
                                    <Column field="name" header="Name" showFilterMenu={false} filter style={{ minWidth: '12rem' }} sortable />
                                    <Column field="email" header="Email" style={{ minWidth: '12rem' }} showFilterMenu={false} filter sortable />
                                    <Column field="mobileNo" header="Mobile" showFilterMenu={false} filter filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '14rem' }} sortable />
                                    <Column field="is_active" header="Status" showFilterMenu={false} filterMenuStyle={{ width: '8rem' }} style={{ minWidth: '8rem' }} filter  sortable />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DialogBox show={showDialogBox} okClick={changeStatus} close={() => { setDialogBox(false); setRowId("") }} message={dialogMessage}></DialogBox>
            <DialogBox show={showunlockDialogBox} okClick={unlockprofile} close={() => { setunlockDialogBox(false); setRowId("") }} message={dialogMessage}></DialogBox>
        </div>
    )
}
export default Patients;